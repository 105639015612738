import {
  defaultEnvironment,
  defaultProdEnvironmentAnalyticsConfig
} from './environment.defaults';

export const environment = {
  ...defaultEnvironment,
  ...defaultProdEnvironmentAnalyticsConfig,

  production: true,
  development: false,
  mobile: false,
  deploymentEnv: 'production',
  file_upload_path: 'https://gql-ps.immomio.com/tenant/fileUpload',
  graphql_server_uri: 'https://gql-ps.immomio.com/tenant/graphql',
  graphql_resident_server_uri: 'https://api-rs.immomio.com/graphql',
  graphql_host: 'https://gql-ps.immomio.com',
  backend_url: 'https://api-ps.immomio.com',
  filer_url: 'https://filer-ps.immomio.com',

  property_searcher_base_url: 'https://tenant.immomio.com',
  property_searcher_home_url: 'https://www.mieter.immomio.com',
  landlord_info_url: 'http://www.immomio.de',
  landlord_app_url: 'https://app.immomio.com',

  keycloakConfig: {
    ...defaultEnvironment.keycloakConfig,

    realm: 'immomio',
    'auth-server-url': 'https://sso.immomio.com/auth/',
    'public-client': false,
    'use-resource-role-mappings': false,
    credentials: {
      secret: 'd1719866-c7f5-44d0-9075-993b17e7675f'
    },
    'policy-enforcer': {}
  }
};
