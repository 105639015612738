import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-schufa-success-modal',
  templateUrl: './schufa-success-modal.component.html',
  styleUrls: ['./schufa-success-modal.component.scss']
})
export class SchufaSuccessModalComponent {
  constructor(private ngbActiveModal: NgbActiveModal) {}

  public dismiss() {
    this.ngbActiveModal.dismiss();
  }

  public close() {
    this.ngbActiveModal.close();
  }
}
