import { Injectable } from '@angular/core';

import { Apollo } from 'apollo-angular';
import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import {
  changeEmailMutation,
  verifyEmailMutation
} from 'tenant-pool/core/queries/resident.queries';
import { ErrorType } from 'tenant-pool/models';

@Injectable()
export class ResidentFacade {
  constructor(private apollo: Apollo) {}

  public verifyEmail(
    token: string,
    verificationCode: string,
    customerIdent: string
  ) {
    const input = {
      token,
      verificationCode
    };
    return this.apollo
      .use('resident')
      .mutate<boolean>({
        mutation: verifyEmailMutation,
        variables: {
          input
        },
        context: {
          headers: {
            'X-Customer-Ident': customerIdent
          }
        }
      })
      .pipe(
        map(response => response.data),
        catchError((error: Error) => {
          if (error.message === ErrorType.RESIDENT_ALREADY_VERIFIED) {
            return of(true);
          }
          throw new Error(error.message);
        })
      );
  }

  public changeEmail(token: string, customerIdent: string) {
    const input = {
      token
    };
    return this.apollo
      .use('resident')
      .mutate<{ success: boolean }>({
        mutation: changeEmailMutation,
        variables: {
          input
        },
        context: {
          headers: {
            'X-Customer-Ident': customerIdent
          }
        }
      })
      .pipe(map(response => response.data.success));
  }
}
