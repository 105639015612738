import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import * as fromAppState from 'tenant-pool/+state';
import { catchError, filter, switchMap } from 'rxjs/operators';
import * as fromBaseState from 'libs/infrastructure/base-state';

@Injectable()
export class MovingChecklistGuard {
  constructor(private store: Store<fromBaseState.AppState>) {}
  canActivate(_route: ActivatedRouteSnapshot) {
    this.store.dispatch(fromAppState.MovingChecklistGetByProperty());

    return this.store.select(fromAppState.getGetByPropertyActionState).pipe(
      filter(state => state.done),
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }
}
