import { NG_VALIDATORS } from '@angular/forms';
import * as i0 from '@angular/core';
import { Directive, Input, Pipe, NgModule } from '@angular/core';
import { isValidIBAN, electronicFormatIBAN, friendlyFormatIBAN } from 'ibantools';
const ibanValidator = countryCode => {
  return control => {
    if (countryCode && control.value) {
      return new RegExp(`^${countryCode}.*$`, 'i').test(control.value) && isValidIBAN(electronicFormatIBAN(control.value)) ? null : {
        iban: {
          value: control.value
        }
      };
    } else if (control.value) {
      return isValidIBAN(electronicFormatIBAN(control.value)) ? null : {
        iban: {
          value: control.value
        }
      };
    }
    return null;
  };
};
class IbanDirective {
  validate(control) {
    return ibanValidator(this.countryCode)(control);
  }
  /* istanbul ignore next */
  registerOnValidatorChange(fn) {
    this._onChange = fn;
  }
  /* istanbul ignore next */
  ngOnChanges(changes) {
    if ('countryCode' in changes && this._onChange) {
      this._onChange();
    }
  }
  static {
    this.ɵfac = function IbanDirective_Factory(t) {
      return new (t || IbanDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: IbanDirective,
      selectors: [["", "ngxIban", ""]],
      inputs: {
        countryCode: [0, "ngxIban", "countryCode"]
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([{
        provide: NG_VALIDATORS,
        useExisting: IbanDirective,
        multi: true
      }]), i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IbanDirective, [{
    type: Directive,
    args: [{
      selector: '[ngxIban]',
      standalone: true,
      providers: [{
        provide: NG_VALIDATORS,
        useExisting: IbanDirective,
        multi: true
      }]
    }]
  }], null, {
    countryCode: [{
      type: Input,
      args: ['ngxIban']
    }]
  });
})();
class IbanPipe {
  transform(value, separator) {
    return value ? friendlyFormatIBAN(value, separator) : null;
  }
  static {
    this.ɵfac = function IbanPipe_Factory(t) {
      return new (t || IbanPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "iban",
      type: IbanPipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IbanPipe, [{
    type: Pipe,
    args: [{
      name: 'iban',
      standalone: true
    }]
  }], null, null);
})();
class NgxIbanModule {
  static {
    this.ɵfac = function NgxIbanModule_Factory(t) {
      return new (t || NgxIbanModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgxIbanModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxIbanModule, [{
    type: NgModule,
    args: [{
      imports: [IbanDirective, IbanPipe],
      exports: [IbanDirective, IbanPipe]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { IbanDirective, IbanPipe, NgxIbanModule, ibanValidator };
