import { Component, Input } from '@angular/core';

import { FormGroup } from '@angular/forms';

import { SelfDisclosureQuestion } from '@ui/shared/models';

@Component({
  selector: 'app-self-disclosure-address',
  templateUrl: './self-disclosure-address.component.html',
  styleUrls: ['./self-disclosure-address.component.scss']
})
export class SelfDisclosureAddressComponent {
  @Input() selfDisclosureQuestion: SelfDisclosureQuestion;
  @Input() form: FormGroup;

  public get getFormControl() {
    return this.form.get('answer') as FormGroup;
  }
}
