import { Component, Input, OnInit } from '@angular/core';
import { DigitalContractWorkflowState } from '@ui/shared/models';
import { BaseStep } from 'libs/components/legacy/wizard/wizard-step/base-step';

@Component({
  selector: 'app-contract-code-verification',
  templateUrl: './contract-code-verification.component.html',
  styleUrls: ['./contract-code-verification.component.scss']
})
export class ContractCodeVerificationComponent
  extends BaseStep
  implements OnInit
{
  @Input() numberOfDigits: number;
  @Input() iban: string;

  ngOnInit(): void {
    super.ngOnInit();
  }

  public onFormValidityChange(isValid: boolean) {
    this.form.setErrors(isValid ? null : { listIsEmpty: true });
  }

  public get isError() {
    return (
      this.aesWorkflowState === DigitalContractWorkflowState.AES_CODE_FAILED
    );
  }

  public get notAllowed() {
    return (
      this.aesWorkflowState ===
      DigitalContractWorkflowState.AES_CODE_NOT_ALLOWED
    );
  }

  public get isRetry() {
    return (
      this.aesWorkflowState === DigitalContractWorkflowState.AES_CODE_RETRY
    );
  }

  public get getIban() {
    return this.iban ? this.iban : '-';
  }

  private get aesWorkflowState() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.form.get('aesWorkflowState').value;
  }
}
