import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';

import { map } from 'rxjs/operators';

import {
  SendSchufaDelivery,
  SendSchufaPayment,
  SchufaPersonalInformation
} from 'tenant-pool/models';
import {
  schufaDelivery,
  SchufaDeliveryResponse,
  SchufaPersonalDataResponse,
  schufaPayment,
  SchufaPaymentResponse,
  schufaPersonalData,
  SchufaOrderResponse,
  schufaOrder
} from 'tenant-pool/core/queries/schufa.queries';

@Injectable()
export class SchufaFacade {
  constructor(private apollo: Apollo) {}

  public personalData(personalInformation: SchufaPersonalInformation) {
    return this.apollo
      .query<SchufaPersonalDataResponse>({
        query: schufaPersonalData,
        variables: {
          input: personalInformation
        },
        fetchPolicy: 'no-cache'
      })
      .pipe(map(response => response.data.schufaPersonalData));
  }

  public payment(payment: SendSchufaPayment, orderNumber: number) {
    return this.apollo
      .query<SchufaPaymentResponse>({
        query: schufaPayment,
        variables: {
          orderNumber,
          input: payment
        },
        fetchPolicy: 'no-cache'
      })
      .pipe(map(response => response.data.schufaPayment));
  }

  public order(orderNumber: number) {
    return this.apollo
      .query<SchufaOrderResponse>({
        query: schufaOrder,
        variables: {
          orderNumber
        },
        fetchPolicy: 'no-cache'
      })
      .pipe(map(response => response.data.schufaOrder));
  }

  public delivery(delivery: SendSchufaDelivery, deliveryNumber: number) {
    return this.apollo
      .query<SchufaDeliveryResponse>({
        query: schufaDelivery,
        variables: {
          deliveryNumber,
          input: delivery
        },
        fetchPolicy: 'no-cache'
      })
      .pipe(map(response => response.data.schufaDelivery));
  }
}
