import { Component, Input } from '@angular/core';

import { FormGroup } from '@angular/forms';

import { SelfDisclosureQuestion } from '@ui/shared/models';
import { defaultDocumentsConfig } from 'libs/config';

@Component({
  selector: 'app-self-disclosure-document',
  templateUrl: './self-disclosure-document.component.html',
  styleUrls: ['./self-disclosure-document.component.scss']
})
export class SelfDisclosureDocumentComponent {
  @Input() selfDisclosureQuestion: SelfDisclosureQuestion;
  @Input() form: FormGroup;

  public acceptedDocumentsTypes = defaultDocumentsConfig.allAcceptedTypes;
  public documentsMaxSize = 1024 * 1024 * 20;

  public get getFormControl() {
    return this.form.get('answer') as FormGroup;
  }

  public get getUploadFormControl() {
    return this.form.get('upload') as FormGroup;
  }

  public get isDocumentValid() {
    return (
      !this.getUploadFormControl.valid && this.getUploadFormControl.touched
    );
  }
}
