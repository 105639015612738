import {
  Component,
  OnInit,
  forwardRef,
  Output,
  EventEmitter,
  Input
} from '@angular/core';

import {
  FormGroup,
  FormBuilder,
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  FormArray,
  FormControl
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { delay } from 'rxjs/operators';
import { Subject } from 'rxjs';

import {
  Attachment,
  Constants,
  Property,
  PropertySearcher,
  PropertySearcherUser,
  PropertySearcherUserProfile,
  SelfDisclosureModel,
  SelfDisclosureQuestion,
  SelfDisclosureQuestionType
} from '@ui/shared/models';
import { isObject } from 'libs/utils';
import { defaultDocumentsConfig } from 'libs/config';

import { SelfDisclosureService } from '../../self-disclosure.service';

const BOOLEAN_OPTIONS = [
  { name: 'general.yes_l', value: true },
  { name: 'general.no_l', value: false }
];

@UntilDestroy()
@Component({
  selector: 'app-self-disclosure-form',
  templateUrl: './self-disclosure-form.component.html',
  styleUrls: ['./self-disclosure-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelfDisclosureFormComponent),
      multi: true
    }
  ]
})
export class SelfDisclosureFormComponent
  implements OnInit, ControlValueAccessor
{
  @Input() selfDisclosureModel: SelfDisclosureModel;

  @Input() userData: PropertySearcherUser;
  @Input() propertySearcher: PropertySearcher;
  @Input() constants: Constants;
  @Input() formValiditySubject: Subject<boolean>;

  @Output() validityChange = new EventEmitter<boolean>();

  public form: FormGroup;
  public value: SelfDisclosureModel;

  public acceptedDocumentsTypes = defaultDocumentsConfig.allAcceptedTypes;
  public documentsMaxSize = 1024 * 1024 * 20;

  private onChange = (value: unknown) => value;
  private onTouch = () => null;

  constructor(
    private fb: FormBuilder,
    private selfDisclosureService: SelfDisclosureService
  ) {}

  public ngOnInit() {
    if (this.formValiditySubject) {
      this.formValiditySubject
        .asObservable()
        .pipe(untilDestroyed(this))
        .subscribe(isValid => {
          if (isValid) {
            this.checkAndValidity(this.questionsFormArray);
            this.questionsFormArray.updateValueAndValidity({ emitEvent: true });
            this.form.updateValueAndValidity({ emitEvent: true });
          }
        });
    }

    this.form = this.fb.group({
      id: [''],
      feedbackEmail: [''],
      questions: this.fb.array([]),
      documents: [[]],
      uploadedDocuments: [[]],
      confirmations: [[]],
      description: [''],
      propertySearcherComment: ['']
    });

    this.form.valueChanges.subscribe((value: SelfDisclosureModel) => {
      this.value = value;
      this.onChange(this.value);
      this.onTouch();
    });

    this.form.statusChanges.pipe(delay(0)).subscribe(value => {
      this.validityChange.emit(value === 'VALID');
    });
  }

  private checkAndValidity(array: FormArray) {
    array.controls.forEach((control: FormGroup) => {
      /**
       * We need to set the touched value of all invalid control fields to show the error message
       * when the save button is clicked.
       */
      const answer = control.get('answer');
      if (
        (answer as FormArray).errors &&
        (answer as FormArray).errors['listIsEmpty']
      ) {
        this.checkAndMarkControl(answer as FormControl);
      } else if ((answer as FormArray).length > 0) {
        this.checkAndValidity(answer as FormArray);
      } else if (!(answer as FormGroup).controls) {
        if (control.value.type === 'DOCUMENT') {
          control.get('upload').setErrors({ required: true });
          this.checkAndMarkControl(control.get('upload') as FormControl);
          control.updateValueAndValidity();
        } else {
          this.checkAndMarkControl(answer as FormControl);
        }
      } else if ((answer as FormGroup).controls) {
        const answerControls = (answer as FormGroup).controls;
        Object.keys(answerControls).forEach(key => {
          const formControl = answerControls[key] as FormControl;
          this.checkAndMarkControl(formControl);
        });
      }

      const confirmations = control.get('confirmations') as FormArray;
      confirmations?.controls?.forEach(item => {
        this.checkAndMarkControl(item as FormControl);
      });

      // recursive call to check validity for sub-questions as well
      const controlsSubQuestions = control.get('subQuestions') as FormArray;
      if (controlsSubQuestions?.length > 0) {
        this.checkAndValidity(controlsSubQuestions);
      }
    });
  }

  private checkAndMarkControl(formControl: FormControl) {
    if (formControl.status === 'INVALID') {
      formControl.markAsDirty();
      formControl.markAsTouched();
      formControl.updateValueAndValidity({ emitEvent: true });
    }
  }

  public get questionsFormArray() {
    return this.form.get('questions') as FormArray;
  }

  private get confirmationFormArray() {
    return this.form.get('confirmations') as FormArray;
  }

  public getQuestionsForm(index: number) {
    return this.questionsFormArray.controls[index] as FormGroup;
  }

  public isValid(form: FormGroup) {
    return form.invalid && form.touched && form?.value?.answer?.length === 0;
  }

  public writeValue(value: SelfDisclosureModel) {
    const model: SelfDisclosureModel = {
      ...value,
      questions: value.questions.map(q => ({
        ...q,
        subQuestions: q.subQuestions.map(sq => ({ ...sq }))
      }))
    };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.confirmationFormArray.value.forEach(() =>
      this.confirmationFormArray.removeAt(0)
    );

    // create the FormArray for questions based on SD model
    this.form.setControl(
      'questions',
      this.selfDisclosureService.createQuestionFormArray(
        model,
        this.selfDisclosureModel.questions
      )
    );

    // pre-fill values
    if (
      !this.selfDisclosureModel.isAnswered &&
      this.userData &&
      this.propertySearcher
    ) {
      this.preFillQuestionFields(model.questions, this.questionsFormArray);
    }

    this.value = model || ([] as SelfDisclosureModel);

    if (this.selfDisclosureModel.isAnswered) {
      this.form.patchValue({
        questions: this.selfDisclosureModel.questions,
        uploadedDocuments: this.selfDisclosureModel.uploadedDocuments,
        propertySearcherComment:
          this.selfDisclosureModel.propertySearcherComment,
        description: this.value.description,
        documents: this.value.documents
      });
    } else {
      this.form.patchValue({
        questions: this.value.questions,
        documents: this.value.documents,
        description: this.value.description,
        uploadedDocuments: this.value.uploadedDocuments || []
      });
    }
  }

  private preFillQuestionFields(
    questionList: SelfDisclosureQuestion[],
    formArray: FormArray
  ) {
    questionList.forEach((question, index) => {
      let value;
      switch (question.title) {
        case 'SELF_DISCLOSURE_PERSONAL_INFO_L': {
          value = this.getPersonalUserData(this.userData.profile);
          if (question.subQuestions) {
            this.preFillQuestionFields(
              question.subQuestions,
              formArray.at(index).get('subQuestions') as FormArray
            );
          }
          break;
        }
        case 'SELF_DISCLOSURE_FLAT_VISITED_L': {
          value = this.getFlatData(this.propertySearcher.property);
          break;
        }
        case 'SELF_DISCLOSURE_CURRENT_ADDRESS_L': {
          value = this.getAddressData(this.userData);
          break;
        }
        case 'SELF_DISCLOSURE_EMPLOYMENT_L': {
          value = this.getEmployerData(this.userData.profile);
          break;
        }
        case 'SELF_DISCLOSURE_DOCUMENT_CREDIT_RATING': {
          const creditReport = this.getDocument(
            this.userData.profile.attachments,
            'CREDIT_REPORT'
          );
          if (isObject(creditReport)) {
            formArray.at(index).get('upload').patchValue(creditReport);
            question.upload = creditReport;
          }
          break;
        }
        case 'SELF_DISCLOSURE_DOCUMENT_INCOME': {
          const incomeStatement = this.getDocument(
            this.userData.profile.attachments,
            'INCOME_STATEMENT'
          );
          if (isObject(incomeStatement)) {
            formArray.at(index).get('upload').patchValue(incomeStatement);
            question.upload = incomeStatement;
          }
          break;
        }
        case 'SELF_DISCLOSURE_DOCUMENT_WBS_L': {
          const wbsDoc = this.getDocument(
            this.userData.profile.attachments,
            'WB_CERTIFICATE'
          );
          if (isObject(wbsDoc)) {
            value = true;
            formArray.at(index).get('upload').patchValue(wbsDoc);
            question.upload = wbsDoc;
          }
          break;
        }
      }
      if (value) {
        formArray.at(index).get('answer').patchValue(value);
        question.answer = value;
      }
    });
  }

  private getDocument(attachments: Attachment[], type: string) {
    return attachments.find(attachment => attachment.type === type);
  }

  private getPersonalUserData(userProfile: PropertySearcherUserProfile) {
    const {
      firstname = null,
      name = null,
      dateOfBirth = null
    } = userProfile || {};
    return {
      firstName: firstname,
      lastName: name,
      birthDate: dateOfBirth
    };
  }

  private getAddressData(user: PropertySearcherUser) {
    return {
      city: user.address ? user.address.city : null,
      street: user.address ? user.address.street : null,
      houseNumber: user.address ? user.address.houseNumber : null,
      zipCode: user.address ? user.address.zipCode : null,
      phone: user.profile.phone,
      email: user.email
    };
  }

  private getEmployerData(userProfile: PropertySearcherUserProfile) {
    return {
      job:
        userProfile && userProfile.profession
          ? userProfile.profession.subType
          : null,
      netIncome:
        userProfile && userProfile.profession
          ? userProfile.profession.income
          : null
    };
  }

  private getFlatData(property: Property) {
    const { totalRent, rooms, size } = property;
    const address = property.address;
    return {
      city: address ? address.city : null,
      street: address ? address.street : null,
      numberOfRooms: rooms,
      sizeInSm: size,
      totalRent: totalRent
    };
  }

  public registerOnChange(fn) {
    this.onChange = fn;
  }

  public registerOnTouched(fn) {
    this.onTouch = fn;
  }

  public isBoolean(question: SelfDisclosureQuestion) {
    return question.type === SelfDisclosureQuestionType.BOOLEAN;
  }

  public showBooleanOrSelect(question: SelfDisclosureQuestion) {
    return (
      question.showSelfDisclosureQuestions &&
      (question.type === SelfDisclosureQuestionType.BOOLEAN ||
        question.type === SelfDisclosureQuestionType.SELECT)
    );
  }

  public showAddress(question: SelfDisclosureQuestion) {
    return (
      question.showSelfDisclosureQuestions &&
      question.type === SelfDisclosureQuestionType.ADDRESS
    );
  }

  public showFlat(question: SelfDisclosureQuestion) {
    return (
      question.showSelfDisclosureQuestions &&
      question.type === SelfDisclosureQuestionType.FLAT
    );
  }

  public showPerson(question: SelfDisclosureQuestion) {
    return (
      question.showSelfDisclosureQuestions &&
      question.type === SelfDisclosureQuestionType.PERSON
    );
  }

  public showPersonList(question: SelfDisclosureQuestion) {
    return (
      question.showSelfDisclosureQuestions &&
      question.type === SelfDisclosureQuestionType.PERSONS
    );
  }

  public showChildren(question: SelfDisclosureQuestion) {
    return (
      question.showSelfDisclosureQuestions &&
      question.type === SelfDisclosureQuestionType.CHILDREN
    );
  }

  public showEmployment(question: SelfDisclosureQuestion) {
    return (
      question.showSelfDisclosureQuestions &&
      question.type === SelfDisclosureQuestionType.EMPLOYMENT
    );
  }

  public options(question, isBoolean) {
    return isBoolean ? BOOLEAN_OPTIONS : [];
  }

  /**
   * Only show hit for positive answer for now.
   * @param index
   */
  public showHint(index: number) {
    const control = this.getQuestionsForm(index).value;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return (
      this.selfDisclosureModel.questions[index] &&
      this.selfDisclosureModel.questions[index].commentAllowed &&
      control &&
      control.answer &&
      (!Array.isArray(control.answer) || control.answer.length)
    );
  }

  public showUpload(index: number) {
    const control = this.getQuestionsForm(index).value;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return (
      this.selfDisclosureModel.questions[index] &&
      this.selfDisclosureModel.questions[index].uploadAllowed &&
      control &&
      control.answer
    );
  }
}
