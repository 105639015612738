import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ActionState } from 'libs/state-utils';
import { Attachment } from '@ui/shared/models';

import { SendSchufaDelivery } from 'tenant-pool/models';
import { SchufaDeliveryResponseData } from 'tenant-pool/core/queries';

import { BaseSchufaStep } from '../../base-schufa-step';

@Component({
  selector: 'app-schufa-finish',
  templateUrl: './schufa-finish.component.html',
  styleUrls: ['./schufa-finish.component.scss']
})
export class SchufaFinishComponent extends BaseSchufaStep implements OnInit {
  @Input() deliveryActionState: ActionState;
  @Input() deliveryData: SendSchufaDelivery;
  @Input() deliveryResponse: SchufaDeliveryResponseData;
  @Input() isLoggedIn: boolean;
  @Output() downloadCreditReportPDF = new EventEmitter<string>();
  @Output() uploadCreditReport = new EventEmitter<Attachment>();
  @Output() login = new EventEmitter();
  @Output() finish = new EventEmitter();

  get hasError() {
    return (
      (this.deliveryResponse.error && this.deliveryResponse.error.length > 0) ||
      this.deliveryResponse.status.description ===
        'Identifikations fehlgeschlagen'
    ); // error description is german
  }

  get creditReportControl() {
    return this.form.get('creditReport');
  }

  public onDownloadCreditReportPDF(url: string) {
    this.downloadCreditReportPDF.emit(url);
  }

  public onUploadCreditReport() {
    this.uploadCreditReport.emit(this.form.value.creditReport);
  }

  public onFinish() {
    this.finish.emit();
  }

  public onLogin() {
    this.login.emit();
  }
}
