import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Inject, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';

import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { WINDOW_REF } from 'libs/infrastructure';
import { CookiePreference } from '@ui/shared/models';
import { fadeOnEnterLeaveAnimation } from 'libs/utils';
import * as fromBaseState from 'libs/infrastructure/base-state';

import { BaseShellComponent } from '../base-shell.component';

@UntilDestroy()
@Component({
  selector: 'app-unauthenticated-shell',
  templateUrl: './unauthenticated-shell.component.html',
  styleUrls: ['./unauthenticated-shell.component.scss'],
  animations: [fadeOnEnterLeaveAnimation]
})
export class UnauthenticatedShellComponent
  extends BaseShellComponent
  implements OnInit
{
  constructor(
    store: Store<fromBaseState.AppState>,
    router: Router,
    route: ActivatedRoute,
    translate: TranslateService,
    observer: BreakpointObserver,
    @Inject(WINDOW_REF) windowRef: Window
  ) {
    super(store, router, route, translate, observer, windowRef);
  }

  public ngOnInit() {
    super.ngOnInit();
    // initially load the preference from LS and enable tracking if configured
    this.store.dispatch(new fromBaseState.InitCookiesPreference());
  }

  public acceptCookies(payload: CookiePreference) {
    this.store.dispatch(new fromBaseState.SetCookiesPreference(payload));
  }

  public customCookieSettings(payload: CookiePreference) {
    this.store.dispatch(
      new fromBaseState.OpenCookieSettingsModal(payload, true)
    );
  }
}
