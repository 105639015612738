import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';

import { SCREEN_MEDIUM_MIN } from 'libs/config';
import { BaseStep } from 'libs/components/legacy/wizard/wizard-step/base-step';

@Component({
  selector: 'app-contract-signing',
  templateUrl: './contract-signing.component.html',
  styleUrls: ['./contract-signing.component.scss']
})
export class ContractSigningComponent
  extends BaseStep
  implements OnInit, OnDestroy
{
  @Input() isSigned = false;
  @Input() hasError = false;
  @Input() showShopCard = false;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onTrackRelocationShop = new EventEmitter();

  public get narrowScreen() {
    return window.innerWidth < SCREEN_MEDIUM_MIN;
  }

  public trackRelocationShop() {
    this.onTrackRelocationShop.emit();
  }
}
