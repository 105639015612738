<div class="main-container container-primary">
  <app-header-v2
    *ngIf="displayHeader"
    class="header__container visible-lg-up"
    [navigationUserDropdownItems]="navigationUserDropdownItems"
    [user]="user$ | async"
    [tenantInInternalPool]="inInternalPool"
    [showLanguagePicker]="true"
    [availableLanguages]="availableLanguages$ | async"
    [defaultLanguageCode]="defaultLanguageCode$ | async"
    [currentLanguageCode]="currentLanguageCode$ | async"
    (languageChange)="onLanguageChange($event)"
    (logout)="onLogout()"
  ></app-header-v2>

  <app-mobile-navigation
    *ngIf="!hideMobileMenu"
    class="visible-md-down"
    [navigationItems]="navigationItemsMobile"
    [user]="user$ | async"
    [hasNewHome]="hasNewHome"
    [unreadCount]="unreadCount$ | async"
    [tenantInInternalPool]="inInternalPool"
    [displayTopNavigation]="true"
    (logout)="onLogout()"
  ></app-mobile-navigation>

  <app-performance-warning-banner
    *ngIf="showWarningBanner"
    [config]="warningBannerConfig"
    [confirmed]="confirmedPerformanceWarning$ | async"
    (confirm)="confirmPerformanceWarning()"
    [@fadeOnEnterLeave]
  ></app-performance-warning-banner>

  <div class="content-container d-flex flex-row">
    <app-main-navigation
      class="visible-lg-up"
      [navigationItems]="navigationItemsDesktop"
      [footerLinks]="footerLinks"
      [unreadCount]="unreadCount$ | async"
    ></app-main-navigation>
    <div class="main-container__scroll-container col p0">
      <div class="banners-container">
        <app-cookie-banner
          class="cookie-banner"
          [cookiePreference]="cookiesPreference$ | async"
          (acceptAll)="acceptCookies($event)"
          (customSettings)="customCookieSettings($event)"
        ></app-cookie-banner>
        <app-verify-email-banner
          *ngIf="displayEmailBanner && ((hasEmailVerified$ | async) !== true || (emailVerifiedInCurrentSession$ | async)) && (hideEmailBanner$ | async) !== true"
          [@fadeOnEnterLeave]
          [emailResent]="verificationEmailResent$ | async"
          [emailVerified]="(hasEmailVerified$ | async) || (emailVerifiedInCurrentSession$ | async)"
          (resend)="resendVerificationEmail()"
        >
        </app-verify-email-banner>
      </div>

      <router-outlet></router-outlet>
    </div>
  </div>

  <aside></aside>
</div>
