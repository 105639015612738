import { SelfDisclosureFormComponent } from './self-disclosure-form/self-disclosure-form.component';
import { SelfDisclosureFlatComponent } from './self-disclosure-flat/self-disclosure-flat.component';
import { SelfDisclosurePersonListComponent } from './self-disclosure-person-list/self-disclosure-person-list.component';
import { SelfDisclosurePersonComponent } from './self-disclosure-person/self-disclosure-person.component';
import { SelfDisclosureAddressComponent } from './self-disclosure-address/self-disclosure-address.component';
import { SelfDisclosureEmploymentComponent } from './self-disclosure-employment/self-disclosure-employment.component';
import { SelfDisclosureChildComponent } from './self-disclosure-child/self-disclosure-child.component';
import { SelfDisclosureChildListComponent } from './self-disclosure-child-list/self-disclosure-child-list.component';
import { SelfDisclosureDocumentComponent } from './self-disclosure-document/self-disclosure-document.component';

export const selfDisclosureComponents = [
  SelfDisclosureFormComponent,
  SelfDisclosureFlatComponent,
  SelfDisclosurePersonComponent,
  SelfDisclosurePersonListComponent,
  SelfDisclosureAddressComponent,
  SelfDisclosureEmploymentComponent,
  SelfDisclosureChildComponent,
  SelfDisclosureChildListComponent,
  SelfDisclosureDocumentComponent
];
