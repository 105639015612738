import { Injectable } from '@angular/core';

import { Apollo } from 'apollo-angular';
import { tap, map } from 'rxjs/operators';

import { getResponseValidator } from 'libs/infrastructure';
import {
  CustomQuestionAnswer,
  HierarchicalRootQuestionResponse
} from '@ui/shared/models';

import {
  saveCustomQuestionResponsesMutation,
  customQuestionsByPropertyQuery,
  CustomQuestionsByPropertyQueryResponse,
  hierarchicalRootQuestionResponseMutation,
  HierarchicalRootQuestionResponseMutationResponse,
  QuestionsByCustomerQueryResponse,
  questionsByCustomerQuery,
  questionsAndAnswersByCustomerQuery,
  QuestionsAndAnswersByCustomerQueryResponse
} from '../queries';

@Injectable()
export class CustomQuestionFacade {
  constructor(private apollo: Apollo) {}

  public getCustomQuestions(id: string) {
    return this.apollo
      .query({
        query: customQuestionsByPropertyQuery,
        variables: { id }
      })
      .pipe(
        tap(getResponseValidator<CustomQuestionsByPropertyQueryResponse>()),
        map(res => res.data.customQuestionsByProperty)
      );
  }

  public saveAnswers(input: CustomQuestionAnswer[]) {
    return this.apollo.mutate({
      mutation: saveCustomQuestionResponsesMutation,
      variables: { input }
    });
  }

  public getQuestionsByCustomer(id: string) {
    return this.apollo
      .query({
        query: questionsByCustomerQuery,
        variables: { id }
      })
      .pipe(
        tap(getResponseValidator<QuestionsByCustomerQueryResponse>()),
        map(res => res.data.questionsByCustomer)
      );
  }

  public getQuestionsAndAnswersByCustomer(id: string) {
    return this.apollo
      .query({
        query: questionsAndAnswersByCustomerQuery,
        variables: { id }
      })
      .pipe(
        tap(getResponseValidator<QuestionsAndAnswersByCustomerQueryResponse>()),
        map(res => res.data.questionsAndAnswersByCustomer)
      );
  }

  public hierarchicalRootQuestionResponse(
    input: HierarchicalRootQuestionResponse[]
  ) {
    return this.apollo
      .mutate<HierarchicalRootQuestionResponseMutationResponse>({
        mutation: hierarchicalRootQuestionResponseMutation,
        variables: { input }
      })
      .pipe(map(response => response.data.hierarchicalRootQuestionResponse));
  }
}
