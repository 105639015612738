import { Component, Input, OnInit } from '@angular/core';

import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { getArrayListValidator } from 'libs/components/legacy/form/controls/validation/validators';

import { SelfDisclosureQuestion } from '@ui/shared/models';
import { SelfDisclosureService } from '../../self-disclosure.service';

@UntilDestroy()
@Component({
  selector: 'app-self-disclosure-child-list',
  templateUrl: './self-disclosure-child-list.component.html',
  styleUrls: ['./self-disclosure-child-list.component.scss']
})
export class SelfDisclosureChildListComponent implements OnInit {
  private _selfDisclosureQuestion: SelfDisclosureQuestion;
  @Input()
  get selfDisclosureQuestion() {
    return this._selfDisclosureQuestion;
  }
  set selfDisclosureQuestion(value) {
    this._selfDisclosureQuestion = { ...value };
  }
  @Input() form: FormGroup;

  public get getFormControls() {
    return (this.getFormArray.controls as FormGroup[]) || [];
  }

  private get getAnswerUnavailable() {
    return this.form.get('answerUnavailable');
  }

  private get getFormArray() {
    return this.form.get('answer') as FormArray;
  }

  constructor(
    private fb: FormBuilder,
    private selfDisclosureService: SelfDisclosureService
  ) {}

  public ngOnInit() {
    this.getAnswerUnavailable.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.selfDisclosureQuestion.answerUnavailable =
          this.getAnswerUnavailable.value;
        this.updateValidity(value);
      });
    this.updateValidity(this.selfDisclosureQuestion.answerUnavailable);
  }

  public removeChild(index: number) {
    this.getFormArray.removeAt(index);
  }

  public addChild() {
    const control = this.selfDisclosureService.getChildConfig(
      this.selfDisclosureQuestion.mandatory &&
        this.selfDisclosureQuestion.showSelfDisclosureQuestions
    );
    this.getFormArray.push(this.fb.group(control));
  }

  private updateValidity(value: boolean) {
    /**
     * Here we add or remove the validators to show the red border only if field is invalid
     */
    if (value) {
      this.getFormArray.clearValidators();
    } else {
      this.getFormArray.setValidators(
        getArrayListValidator(
          this.selfDisclosureQuestion.mandatory &&
            this.selfDisclosureQuestion.showSelfDisclosureQuestions
        )
      );
    }
    this.getFormArray.updateValueAndValidity();
  }
}
