<div
  class="d-flex justify-content-center align-items-center main-nav ios-padding-bottom"
>
  <ul class="navbar-nav flex-row justify-content-between w-100">
    <li
      *ngFor="let item of visibleItems"
      class="main-nav-item col d-flex justify-content-center"
      routerLinkActive="active"
      [routerLink]="getItemLink(item)"
    >
      <a
        class="nav-link d-flex flex-column align-items-center"
        [class.active]="setActiveNav(item)"
      >
        <ng-container *ngIf="item.name !== 'profile'; else profile">
          <svg-icon
            src="/assets/images/icons/{{item.icon}}.svg"
            [applyClass]="true"
            [svgStyle]="{'width.px': 25, 'height.px': 25}"
            class="default-svg-color path rect polyline polygon circle line"
            routerLinkActive="white-svg-color"
            [class.white-svg-color]="setActiveNav(item)"
          ></svg-icon>
        </ng-container>
        <ng-template #profile>
          <svg-icon
            *ngIf="!user?.profile?.portrait?.url; else profilePicture"
            src="/assets/images/icons/{{item.icon}}.svg"
            [applyClass]="true"
            [svgStyle]="{'width.px': 25, 'height.px': 25}"
            class="default-svg-color path rect polyline polygon circle line"
            routerLinkActive="white-svg-color"
            [class.white-svg-color]="setActiveNav(item)"
          ></svg-icon>
          <ng-template #profilePicture>
            <div class="navbar__profile-picture">
              <img [src]="user?.profile?.portrait?.url" alt="" />
            </div>
          </ng-template>
        </ng-template>
        <span class="mt5">
          <ng-container *ngIf="isMobile && item?.mobileLabel; else desktop">
            {{item.mobileLabel | translate}}
          </ng-container>
          <ng-template #desktop> {{item.label | translate}} </ng-template>
        </span>
        <span
          class="unread-counter"
          *ngIf="unreadCount > 0 && item.name === 'messenger'"
          >{{ unreadCount }}</span
        >
      </a>
    </li>
  </ul>
</div>
