import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { defaultAdvertising } from 'tenant-pool/config/hideTypes';

@Component({
  selector: 'app-appointment-success-modal',
  templateUrl: './appointment-success-modal.component.html',
  styleUrls: ['./appointment-success-modal.component.scss']
})
export class AppointmentSuccessModalComponent {
  @Input() schufaCard: boolean;

  constructor(private ngbActiveModal: NgbActiveModal) {}

  public get showSchufaLink(): boolean {
    return this.schufaCard ?? defaultAdvertising.schufaCard;
  }

  public close(): void {
    this.ngbActiveModal.close();
  }

  public dismiss(): void {
    this.ngbActiveModal.dismiss();
  }
}
