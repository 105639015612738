import { Inject, Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';

import {
  map,
  switchMap,
  catchError,
  withLatestFrom,
  tap,
  mergeMap
} from 'rxjs/operators';

import * as fromBaseState from 'libs/infrastructure/base-state';
import {
  DownloadService,
  errorMessageParser,
  getResponseValidator,
  WINDOW_REF
} from 'libs/infrastructure';

import { DigitalContractFacade } from 'tenant-pool/core/services';
import { notificationConfig as notification } from 'tenant-pool/config';
import { SignContractStepsRoutes } from 'tenant-pool/components/digital-contract/sign-contract.childRoutes';

import * as fromReducers from './digital-contract.reducers';
import * as fromActions from './digital-contract.actions';
import * as fromSelectors from './digital-contract.selectors';

@Injectable()
export class DigitalContractEffects {
  constructor(
    private actions$: Actions,
    private facade: DigitalContractFacade,
    private store: Store<fromReducers.DigitalContractState>,
    private downloadService: DownloadService,
    @Inject(WINDOW_REF) private windowRef: Window
  ) {}

  loadContract$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.LoadDigitalContract>(
        fromActions.LOAD_DIGITAL_CONTRACT
      ),
      map(action => action.token),
      switchMap(token =>
        this.facade.loadDigitalContract(token).pipe(
          map(
            digitalContract =>
              new fromActions.LoadDigitalContractSuccess(digitalContract)
          ),
          catchError(error => [
            new fromBaseState.ShowError(
              notification.digitalContract.load.error
            ),
            new fromActions.LoadDigitalContractFail(error)
          ])
        )
      )
    )
  );

  sendMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.SendMessage>(fromActions.SEND_MESSAGE),
      map(action => action.token),
      switchMap(token =>
        this.facade.sendMessage(token).pipe(
          tap(getResponseValidator()),
          mergeMap(() => [
            new fromActions.SendMessageSuccess(),
            new fromBaseState.ShowInfo(
              notification.digitalContract.sendMessage.success
            )
          ]),
          catchError(err => [
            new fromBaseState.ShowError(
              notification.digitalContract.sendMessage.error
            ),
            new fromActions.SendMessageFail(
              err ? err.message : 'Unexpected error'
            )
          ])
        )
      )
    )
  );

  sendTenantInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.SendTenantInfo>(fromActions.SEND_TENANT_INFO),
      switchMap(({ info, signatureType }) =>
        this.facade.sendTenantInfo(info, signatureType).pipe(
          map(result => new fromActions.SendTenantInfoSuccess(result)),
          catchError(err => [
            new fromBaseState.ShowError(
              notification.digitalContract.sendTenantInfo.error
            ),
            new fromActions.SendTenantInfoFail(
              err ? err.message : 'Unexpected error'
            )
          ])
        )
      )
    )
  );

  getSigningUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.GetSigningUrl>(fromActions.GET_SIGNING_URL),
      switchMap(({ token, redirectUrl }) =>
        this.facade.getSigningUrl(token, redirectUrl).pipe(
          map(
            docuSignResponse =>
              new fromActions.GetSigningUrlSuccess(docuSignResponse)
          ),
          catchError(err => [
            new fromBaseState.ShowError(
              notification.digitalContract.getSigningUrl.error
            ),
            new fromActions.GetSigningUrlFail(
              err ? err.message : 'Unexpected error'
            )
          ])
        )
      )
    )
  );

  downloadContract$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.DownloadContract>(
        fromActions.DOWNLOAD_DIGITAL_CONTRACT
      ),
      tap(({ token, identifier }) =>
        this.downloadService.downloadDigitalContract(token, identifier)
      ),
      map(() => new fromActions.DownloadContractSuccess()),
      catchError(error => [
        new fromActions.DownloadContractFail(error),
        new fromBaseState.ShowError(notification.digitalContract.download.error)
      ])
    )
  );

  goToDocuSign$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<fromActions.GoToDocuSign>(fromActions.GO_TO_DOCUSIGN),
        map(action => action.docuSignUrl),
        tap((docuSignUrl: string) => {
          this.windowRef.open(docuSignUrl, '_self');
        })
      ),
    { dispatch: false }
  );

  sign$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.Sign>(fromActions.SIGN),
      map(() => new fromBaseState.Go({ path: ['digitalContract'] }))
    )
  );

  verifyCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.VerifyCode>(fromActions.VERIFY_CODE),
      map(
        () =>
          new fromBaseState.Go({
            path: ['digitalContract', 'codeVerification']
          })
      )
    )
  );

  qesCheck$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.StartQesCheck>(fromActions.START_QES_CHECK),
      switchMap(({ qesCheck }) =>
        this.facade.qesCheck(qesCheck).pipe(
          map(res => new fromActions.StartQesCheckSuccess(res.targetUrl)),
          catchError(err => [
            new fromActions.StartQesCheckFail(new Error(err)),
            new fromBaseState.ShowError(errorMessageParser(err.message))
          ])
        )
      )
    )
  );

  currentStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.GetCurrentState>(fromActions.GET_CURRENT_STATE),
      switchMap(({ token }) =>
        this.facade.currentStatus(token).pipe(
          map(aesStatus => new fromActions.GetCurrentStateSuccess(aesStatus)),
          catchError(err => [
            new fromBaseState.ShowError(
              notification.digitalContract.getAesStatus.error
            ),
            new fromActions.GetCurrentStateFail(
              err ? err.message : 'Unexpected error'
            )
          ])
        )
      )
    )
  );

  changeStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.DigitalContractWizardNextStep>(
        fromActions.DIGITAL_CONTRACT_WIZARD_NEXT_STEP,
        fromActions.DIGITAL_CONTRACT_WIZARD_PREVIOUS_STEP
      ),
      map(action => action.contractId || 'new'),
      withLatestFrom(
        this.store.select(fromSelectors.getContractWizardStepNumber)
      ),
      map(([_contractId, currentStepNumber]) => {
        return new fromBaseState.Go({
          path: [
            'digitalContract',
            SignContractStepsRoutes[currentStepNumber - 1].path
          ]
        });
      })
    )
  );
}
