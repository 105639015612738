import { createReducer, on } from '@ngrx/store';
import { ServiceCard } from '@ui/shared/models';
import { ActionState, ActionStateCreator } from 'libs/state-utils';
import * as fromActions from './service-cards.actions';

export interface ServiceCardsState {
  serviceCards: ServiceCard[];
  serviceCardsActionState: ActionState;
  isMilesCardAllowed: boolean;
}

export const initialState: ServiceCardsState = {
  serviceCards: [],
  serviceCardsActionState: ActionStateCreator.create(),
  isMilesCardAllowed: false
};

export const reducer = createReducer(
  initialState,

  on(fromActions.fetchServiceCards, state => ({
    ...state,
    cardsActionState: ActionStateCreator.onStart()
  })),

  on(fromActions.fetchServiceCardsSuccess, (state, { serviceCards }) => ({
    ...state,
    cardsActionState: ActionStateCreator.onSuccess(),
    serviceCards: serviceCards
  })),

  on(fromActions.fetchServiceCardsFail, (state, { error }) => ({
    ...state,
    cardsActionState: ActionStateCreator.onError(error)
  })),
  on(fromActions.loadMilesCardAllowedSuccess, (state, { isAllowed }) => ({
    ...state,
    isMilesCardAllowed: isAllowed
  })),
  on(fromActions.loadMilesCardAllowedError, state => ({
    ...state,
    isMilesCardAllowed: false
  }))
);

export const getServiceCards = (state: ServiceCardsState) => state.serviceCards;
