import { BreakpointObserver } from '@angular/cdk/layout';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';

import { FooterLink } from 'libs/components/legacy/footer';
import { desktopBreakingPoints } from 'libs/components/legacy/messenger/model/interface';

import { NavigationItem } from 'tenant-pool/models';

@UntilDestroy()
@Component({
  selector: 'app-main-navigation',
  templateUrl: './main-navigation.component.html',
  styleUrls: ['./main-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainNavigationComponent implements OnChanges {
  @Input() navigationItems: NavigationItem[];
  @Input() footerLinks: FooterLink[] = [];
  @Input() unreadCount: number;
  @Output() trackEvent = new EventEmitter<string>();

  public currentItem: NavigationItem;
  public visibleItems: NavigationItem[];
  public messengerItem: NavigationItem;

  constructor(
    private router: Router,
    private observer: BreakpointObserver
  ) {}

  public ngOnChanges() {
    this.visibleItems = this.navigationItems?.filter(item => !item.hide);

    this.messengerItem = {
      name: 'messenger',
      label: 'navigation.messenger_l',
      link: '/messenger',
      icon: 'icon-messenger'
    };

    this.observer
      .observe(desktopBreakingPoints)
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (result.matches) {
          this.visibleItems = this.navigationItems
            ?.filter(item => !item.hide)
            ?.filter(item => !item.hideOnDesktop);
        }
      });

    this.currentItem = this.findCurrentItem(
      this.router.routerState.snapshot.url
    );

    this.router.events
      .pipe(filter(event => !!(event instanceof NavigationEnd)))
      .subscribe((event: NavigationEnd) => {
        return (this.currentItem = this.findCurrentItem(event.url));
      });
  }

  private findCurrentItem(currentUrl: string) {
    return this.navigationItems?.find(
      item => currentUrl.indexOf(item.link) > -1
    );
  }
}
