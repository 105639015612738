import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface BankDataPayload {
  valid?: boolean; // either true or false
  messages?: string[]; // states the reason if valid is false,
  iban?: string; // the IBAN which was to be validated
  bankData?: BankData;
  checkResults?: CheckResults;
}

export interface CheckResults {
  bankCode?: boolean; // info about your additional check requests
}

export interface BankData {
  bankCode?: number;
  name?: string;
  zip?: number;
  city?: string;
  bic?: string;
}

@Injectable()
export class IbanCheckerService {
  constructor(private http: HttpClient) {}

  public getBankNameByIban(iban: string): Observable<BankDataPayload> {
    if (!iban) return;
    return this.http.request(
      'GET',
      `https://openiban.com/validate/${iban}?getBIC=true&validateBankCode=true`
    );
  }
}
