import { mainNavigationComponents } from './main-navigation';
import { MobileNavigationComponent } from './mobile-navigation/mobile-navigation.component';
import { AuthenticatedShellComponent } from './authenticated-shell.component';

export const authenticatedShellComponents = [
  ...mainNavigationComponents,
  MobileNavigationComponent,
  AuthenticatedShellComponent
];

export { AuthenticatedShellComponent };
