import { Inject, Injectable } from '@angular/core';
import { RegexTypes } from 'libs/utils';
import { ENVIRONMENT_CONFIG, EnvironmentConfig } from '../environment';

@Injectable()
export class UrlUtilService {
  constructor(
    @Inject(ENVIRONMENT_CONFIG) private environment: EnvironmentConfig
  ) {}

  public getLogoRedirectUrl(url = '') {
    return RegexTypes.URL.exec(url)
      ? url
      : this.environment.property_searcher_home_url;
  }
}
