import { Injectable } from '@angular/core';

import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
  withLatestFrom
} from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import * as fromBaseState from 'libs/infrastructure/base-state';
import { ModalService } from 'libs/components/legacy/modal';

import { SelfDisclosureFacade } from 'tenant-pool/core/services';
import { notificationConfig as notification } from 'tenant-pool/config';
import { SelfDisclosureModalComponent } from 'tenant-pool/components/self-disclosure/self-disclosure-modal/self-disclosure-modal.component';
import * as fromAppReducers from 'tenant-pool/+state/reducers';

import * as fromActions from './self-disclosure.actions';
import * as fromSelector from './self-disclosure.selectors';

@Injectable()
export class SelfDisclosureEffects {
  constructor(
    private actions$: Actions,
    private selfDisclosureFacade: SelfDisclosureFacade,
    private modalService: ModalService,
    private store: Store<fromAppReducers.AppState>
  ) {}

  loadSelfDisclosureModel$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.LoadSelfDisclosureModel>(
        fromActions.LOAD_SELF_DISCLOSURE_MODEL
      ),
      switchMap(({ propertyId }) =>
        this.selfDisclosureFacade.loadSelfDisclosureModel(propertyId).pipe(
          map(
            ({ selfDisclosureModel }) =>
              new fromActions.LoadSelfDisclosureModelSuccess(
                selfDisclosureModel
              )
          ),
          catchError(err => [
            new fromActions.LoadSelfDisclosureModelFail(err && err.message),
            new fromBaseState.ShowError(notification.selfDisclosure.load.error)
          ])
        )
      )
    )
  );

  getSelfDisclosureAnswers$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.LoadSelfDisclosureAnswers>(
        fromActions.LOAD_SELF_DISCLOSURE_ANSWERS
      ),
      switchMap(({ propertySearcherId }) =>
        this.selfDisclosureFacade.getAnswers(propertySearcherId).pipe(
          map(
            answers => new fromActions.LoadSelfDisclosureAnswersSuccess(answers)
          ),
          catchError(err => [
            new fromActions.LoadSelfDisclosureAnswersFail(err && err.message),
            new fromBaseState.ShowError(
              notification.selfDisclosure.loadAnswer.error
            )
          ])
        )
      )
    )
  );

  saveSelfDisclosureAnswers$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.SaveSelfDisclosureAnswers>(
        fromActions.SAVE_SELF_DISCLOSURE_ANSWERS
      ),
      switchMap(({ id, answers }) =>
        this.selfDisclosureFacade.saveAnswers(id, answers).pipe(
          mergeMap(() => [
            new fromActions.SaveSelfDisclosureAnswersSuccess(answers),
            new fromBaseState.ShowInfo(
              notification.selfDisclosure.answer.success
            )
          ]),
          catchError(err => [
            new fromActions.SaveSelfDisclosureAnswersFail(err && err.message),
            new fromBaseState.ShowError(
              notification.selfDisclosure.answer.error
            )
          ])
        )
      )
    )
  );

  loadSelfDisclosureData$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.LoadSelfDisclosureData>(
        fromActions.LOAD_SELF_DISCLOSURE_DATA
      ),
      mergeMap(({ propertySearcher }) => [
        new fromActions.SavePropertySearcher(propertySearcher),
        new fromActions.LoadSelfDisclosureModel(propertySearcher.property.id),
        new fromActions.LoadSelfDisclosureAnswers(propertySearcher.id)
      ])
    )
  );

  openSelfDisclosureModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<fromActions.OpenSelfDisclosuresModal>(
          fromActions.OPEN_SELF_DISCLOSURE_MODAL
        ),
        switchMap(() =>
          this.store.select(fromSelector.getLoadResponsesActionState).pipe(
            filter(
              loadResponsesActionState => !loadResponsesActionState.pending
            ),
            take(1),
            switchMap(() =>
              this.store
                .select(fromSelector.getLoadModelActionState)
                .pipe(
                  filter(loadModelActionState => !loadModelActionState.pending),
                  take(1)
                )
                .pipe(
                  withLatestFrom(
                    this.store.select(fromSelector.getSelfDisclosureResponses),
                    this.store.select(fromSelector.getSelfDisclosureModel),
                    this.store.select(
                      fromSelector.getSelfDisclosurePropertySearcher
                    )
                  ),
                  filter(
                    ([
                      _,
                      selfDisclosureResponses,
                      selfDisclosureModel,
                      propertyMatch
                    ]) =>
                      !!propertyMatch &&
                      !!selfDisclosureModel &&
                      !!selfDisclosureResponses
                  ),
                  take(1),
                  tap(
                    ([
                      _,
                      selfDisclosureResponses,
                      selfDisclosureModel,
                      propertyMatch
                    ]) =>
                      this.modalService.open<SelfDisclosureModalComponent>(
                        SelfDisclosureModalComponent,
                        {
                          backdrop: 'static',
                          keyboard: false,
                          data: {
                            selfDisclosureModel,
                            selfDisclosureResponses,
                            propertyMatch
                          }
                        }
                      )
                  )
                )
            )
          )
        )
      ),
    { dispatch: false }
  );
}
