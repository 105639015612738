<form [formGroup]="formGroup" class="personal-information">
  <div formGroupName="tenantData">
    <div class="personal-information__main-header">
      <span>{{ 'rent_deposit.personal_information.title_l' | translate }}</span>
    </div>

    <div class="row">
      <div class="col-12 mb20">
        <app-form-field>
          <app-form-field-label [showRequiredMarker]="true"
            >{{'SALUTE_L' | translate}}</app-form-field-label
          >
          <app-flat-select
            class="personal-information__gender"
            required
            [items]="tenantGenderList"
            formControlName="gender"
          >
          </app-flat-select>
        </app-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <app-form-field>
          <app-form-field-label [showRequiredMarker]="true"
            >{{'general.first_name_l' | translate}}</app-form-field-label
          >
          <input
            appInput
            type="text"
            required
            [placeholder]="'forms.type_name' | translate"
            formControlName="firstname"
            class="form-control"
          />
        </app-form-field>
      </div>

      <div class="col-6">
        <app-form-field>
          <app-form-field-label [showRequiredMarker]="true"
            >{{'general.last_name_l' | translate}}</app-form-field-label
          >
          <input
            appInput
            type="text"
            required
            [placeholder]="'forms.type_name' | translate"
            formControlName="lastname"
            class="form-control"
          />
        </app-form-field>
      </div>
    </div>

    <div class="row mt10">
      <div class="col-6">
        <app-form-field>
          <app-form-field-label [showRequiredMarker]="true"
            >{{'general.birthdate_l' | translate}}</app-form-field-label
          >
          <app-date
            formControlName="dateOfBirth"
            [required]="true"
            [startDate]="startBirthDate"
            [minDate]="minBirthDate"
            [maxDate]="maxBirthDate"
          >
          </app-date>
        </app-form-field>
      </div>
    </div>

    <div class="personal-information__sub-header">
      <span
        >{{ 'rent_deposit.personal_information.contact_title_l' | translate
        }}</span
      >
    </div>

    <div class="row">
      <div class="col-12">
        <app-form-field>
          <app-form-field-label [showRequiredMarker]="true"
            >{{'general.email_l' | translate}}</app-form-field-label
          >
          <input
            appInput
            type="text"
            required
            [placeholder]="'forms.type_email' | translate"
            formControlName="emailAddress"
            class="form-control"
          />
        </app-form-field>
      </div>
    </div>

    <div class="row mt10">
      <div class="col-4 col-md-3">
        <app-form-field>
          <app-form-field-label [showRequiredMarker]="true"
            >{{'general.phone_country_code_l' |
            translate}}</app-form-field-label
          >
          <app-select
            [required]="true"
            [itemLabelKey]="'value'"
            [itemValueKey]="'value'"
            [items]="internationalOptions"
            [placeholder]="'forms.select_international_type_l' | translate"
            formControlName="phoneNumberPrefix"
          >
          </app-select>
        </app-form-field>
      </div>
      <div class="col col-md-4">
        <app-form-field>
          <app-form-field-label [showRequiredMarker]="true"
            >{{'general.phone_l' | translate}}</app-form-field-label
          >
          <input
            appInput
            pattern="[0-9]*"
            type="text"
            required
            [placeholder]="'forms.type_phone_no_prefix' | translate"
            formControlName="phoneNumber"
            class="form-control"
          />
        </app-form-field>
      </div>
    </div>

    <div class="personal-information__sub-header">
      <span
        >{{ 'rent_deposit.personal_information.address_title_l' | translate
        }}</span
      >
      <app-hint class="pl6"
        >{{ 'rent_deposit.personal_information.address_hint_l' | translate
        }}</app-hint
      >
    </div>

    <div class="row">
      <div class="col-12">
        <app-address-form
          [formGroup]="addressForm"
          [required]="true"
          [showDistricts]="false"
          [showRegion]="false"
          [config]="addressFormConfig"
        >
        </app-address-form>
      </div>
    </div>

    <div class="row">
      <div class="col-12 p0">
        <!--   rent_deposit.complete_a   -->
        <app-wizard-footer
          [disabled]="formGroup.invalid"
          (nextStep)="nextStep()"
          [hasNext]="true"
        ></app-wizard-footer>
      </div>
    </div>
  </div>
</form>
