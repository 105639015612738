import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';

import { ReasonData } from '@ui/shared/models';

import {
  acceptProposalMutation,
  denyProposalMutation,
  AcceptProposalMutationResponse,
  DenyProposalMutationResponse
} from '../queries';

import { PropertyMatchesFacade } from './property-matches.facade';

@Injectable()
export class ProposalFacade {
  constructor(
    private apollo: Apollo,
    private propertySearcherFacade: PropertyMatchesFacade
  ) {}

  public denyProposal(reasonData: ReasonData) {
    return this.apollo.mutate<DenyProposalMutationResponse>({
      mutation: denyProposalMutation,
      variables: { reasonData },
      fetchPolicy: 'no-cache'
    });
  }

  public acceptProposal(id: string) {
    return this.apollo.mutate<AcceptProposalMutationResponse>({
      mutation: acceptProposalMutation,
      variables: { id },
      fetchPolicy: 'no-cache',
      update: () => this.updateQueries(id)
    });
  }

  public updateQueries(id?: string) {
    this.propertySearcherFacade.refetchMatch(id);
  }
}
