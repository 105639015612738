<div class="self-disclosure-person-list__content">
  <form [formGroup]="form">
    <span>{{selfDisclosureQuestion.title | translate}}</span>
    <span
      *ngIf="selfDisclosureQuestion.mandatory"
      class="required-marker ng-star-inserted"
      >&nbsp;*</span
    >

    <div *ngFor="let person of getFormControls; index as i">
      <app-self-disclosure-person
        [form]="person"
        [title]="('self_disclosure.answer.person_list_subheader' | translate) + ' ' + (i + 1)"
        [selfDisclosureQuestion]="selfDisclosureQuestion"
        [showRemove]="true"
        [constants]="constants"
        (onRemove)="removePerson(i)"
      ></app-self-disclosure-person>
    </div>

    <div class="row mt20 self-disclosure-person-list__choices">
      <div
        class="col"
        *ngIf="selfDisclosureQuestion.mandatory && !getFormControls.length"
      >
        <app-form-field>
          <app-checkbox
            [label]="'self_disclosure.answer.person_list_single_tenant_l' | translate"
            formControlName="answerUnavailable"
          ></app-checkbox>
        </app-form-field>
      </div>
      <div class="col">
        <app-button
          [disabled]="selfDisclosureQuestion.answerUnavailable"
          (clickEvent)="addPerson()"
          >{{'self_disclosure.answers.add_person_a' | translate}}</app-button
        >
      </div>
    </div>
  </form>
</div>
