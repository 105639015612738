import { Component, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import * as fromBaseState from 'libs/infrastructure/base-state';
import { MainPageNavigation } from 'tenant-pool/config';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-confirm-apply-modal',
  templateUrl: './confirm-apply-modal.component.html',
  styleUrls: ['./confirm-apply-modal.component.scss']
})
export class ConfirmApplyModalComponent {
  @Input() profileComplete: boolean;
  @Input() showSchufaLink: boolean;
  @Input() profileCompleteness$: Observable<number>;
  @Input() hideApplyNowButton = false;
  @Input() showGenericText = false;

  constructor(
    private ngbActiveModal: NgbActiveModal,
    private store: Store
  ) {}

  public editProfile() {
    this.store.dispatch(
      new fromBaseState.Go({
        path: [MainPageNavigation.PROFILE, 'edit', 'step']
      })
    );
  }

  public cancel() {
    this.ngbActiveModal.dismiss();
  }

  public apply() {
    this.ngbActiveModal.close();
  }

  public onEditProfile() {
    this.cancel();
    this.editProfile();
  }
}
