import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors
} from '@angular/forms';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, timer } from 'rxjs';
import { map, switchMap, tap, filter, take } from 'rxjs/operators';
import * as fromGuestState from '../../+state/guest-mode';

@Injectable()
export class AsyncValidatorsService {
  constructor(private store: Store<fromGuestState.GuestModeState>) {}

  guestEmailValidator(propertyId: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      if (!control.value || !control.dirty) return of(null);

      return timer(500).pipe(
        tap(() =>
          this.store.dispatch(
            new fromGuestState.CheckGuestApplicationPossible(
              control.value,
              propertyId.toString()
            )
          )
        ),
        switchMap(() =>
          this.store.select(fromGuestState.getGuestRegisterLoading).pipe(
            filter(loading => !loading),
            take(1),
            switchMap(() =>
              this.store.select(fromGuestState.getGuestRegisterStatus).pipe(
                filter(guestState => !!guestState),
                take(1),
                map(
                  ({
                    applicationPossible,
                    alreadyGuest,
                    alreadyRegistered
                  }) => {
                    return applicationPossible
                      ? null
                      : alreadyGuest
                        ? { alreadyApplied: true }
                        : alreadyRegistered
                          ? { alreadyRegistered: true }
                          : { unknownError: true };
                  }
                )
              )
            )
          )
        )
      );
    };
  }
}
