import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { LocationSearchResultEntry } from 'libs/queries/location-search.queries';

@Component({
  selector: 'app-schufa-address',
  templateUrl: './schufa-address.component.html',
  styleUrls: ['./schufa-address.component.scss']
})
export class SchufaAddressComponent {
  @Input() form: FormGroup;
  @Input() addressTitle: string;
  @Input() addressHint: string;
  @Input() removable: boolean;
  @Input() index: number;
  @Input() useStreetForSearch: boolean;
  @Output() remove = new EventEmitter();

  public onLocationSearchSelect(value: LocationSearchResultEntry) {
    this.form.patchValue({
      city: value.properties.city,
      street: value.properties.street,
      zipCode: value.properties.postcode,
      houseNumber: value.properties.housenumber
    });
  }

  public onRemove() {
    this.remove.emit();
  }
}
