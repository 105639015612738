import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output
} from '@angular/core';

import { FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

import moment from 'moment';

import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { SelfDisclosureQuestion } from '@ui/shared/models';
import { BaseControl } from 'libs/components/legacy/form';

@Component({
  selector: 'app-self-disclosure-child',
  templateUrl: './self-disclosure-child.component.html',
  styleUrls: ['./self-disclosure-child.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelfDisclosureChildComponent),
      multi: true
    }
  ]
})
export class SelfDisclosureChildComponent
  extends BaseControl<any>
  implements OnInit
{
  @Input() selfDisclosureQuestion: SelfDisclosureQuestion;
  @Input() title: string;
  @Input() form: FormGroup;
  @Input() showRemove = false;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onRemove = new EventEmitter<void>();

  public maxDateStruct: NgbDateStruct = {
    year: moment().year(),
    month: moment().month() + 1,
    day: moment().date()
  };

  public startDateStruct: NgbDateStruct = {
    year: 1990,
    month: 1,
    day: 1
  };

  public minDateStruct: NgbDateStruct = {
    year: 1900,
    month: 1,
    day: 1
  };

  public writeValue(value: any) {
    this.value = value ? value : [];
  }

  ngOnInit(): void {
    this.title = this.title || this.selfDisclosureQuestion.title;
  }

  public get getAnswerFormControl() {
    return this.form.get('answer') as FormGroup;
  }

  public removeHandler() {
    this.onRemove.emit();
  }
}
