<ul class="main-navigation navbar-nav row col">
  <ng-container *ngFor="let item of visibleItems">
    <ng-container [ngSwitch]="item?.children?.length > 0">
      <app-main-navigation-item
        *ngSwitchCase="false"
        [item]="item"
        (trackEvent)="onTrackClick($event)"
      >
        <span
          class="unread-counter"
          *ngIf="unreadCount > 0 && item.name === 'messenger'"
          >{{ unreadCount }}</span
        >
      </app-main-navigation-item>
      <li class="nav-item d-inline-block" ngbDropdown *ngSwitchCase="true">
        <a
          class="nav-link dropdown-toggle"
          id="navbarDropdown"
          ngbDropdownToggle
        >
          {{item.label | translate}}
          <img src="/assets/images/icons/icon-dropdown.svg" />
        </a>
        <div
          ngbDropdownMenu
          class="dropdown-menu"
          aria-labelledby="navbarDropdown"
        >
          <div class="dropdown-list">
            <a
              *ngFor="let child of item.children"
              class="dropdown-item"
              routerLink="{{ child.link }}"
            >
              {{child.label | translate}}
            </a>
          </div>
        </div>
      </li>
    </ng-container>
  </ng-container>
</ul>
<hr class="m0 mb30" />
<app-footer-v2 [links]="footerLinks"></app-footer-v2>
