import { Inject, Injectable } from '@angular/core';
import Keycloak, { KeycloakConfig, KeycloakLoginOptions } from 'keycloak-js';

import {
  AuthTokenService,
  InfrastructureConfig,
  WINDOW_REF
} from 'libs/infrastructure';

import { INFRASTRUCTURE_CONFIG } from 'libs/infrastructure/infrastructure-config.token';
import { KeycloakTenantService } from 'libs/infrastructure/keycloak-authentication-module/services/keycloak-tenant.service';
import { BehaviorSubject } from 'rxjs';
import {
  AuthToken,
  FetchKeycloakJSON,
  KcConfig,
  KeycloakLoginResponse
} from '../model';
import { KEYCLOAK_OPTIONS } from '../contant';

@Injectable()
export class KeycloakTenantWebService {
  private readonly keycloakConfig: FetchKeycloakJSON;
  private keycloakInstance: Keycloak.KeycloakInstance;
  private initialized: BehaviorSubject<boolean>;

  constructor(
    private authTokenService: AuthTokenService,
    private keycloakService: KeycloakTenantService,
    @Inject(KEYCLOAK_OPTIONS) kcConfig: KcConfig,
    @Inject(WINDOW_REF) private windowRef: Window,
    @Inject(INFRASTRUCTURE_CONFIG) private infrastructure: InfrastructureConfig
  ) {
    this.keycloakConfig = kcConfig.jsonConfig;
    this.initialized = new BehaviorSubject(false);
  }

  public async init() {
    await this.initKeycloak();
    this.initialized.next(true);
    void this.refresh(
      `${this.infrastructure.environment.property_searcher_home_url}/login`
    );
  }

  public keycloakInitialized() {
    return this.initialized;
  }

  public login(options: KeycloakLoginOptions) {
    try {
      this.beginLogin(options);
    } catch (err) {
      const context = { messageError: 'Keycloak Error: error by login' };
      Object.assign(err, { context });
      throw err;
    }
  }

  public exchangeToken(token: string, realm: string, clientId: string) {
    void this.keycloakService.getExchangeToken(token, realm, clientId);
  }

  public logout(redirectUrl: string) {
    this.authTokenService.removeToken();
    const url = this.getLogoutUrl(redirectUrl);
    this.windowRef.location.href = url;
  }

  private getLogoutUrl(redirectUrl: string): string {
    return this.keycloakInstance.createLogoutUrl({
      redirectUri:
        this.infrastructure.environment.property_searcher_home_url +
        '/' +
        encodeURIComponent(redirectUrl)
    });
  }

  private async initKeycloakInstance() {
    const keycloakConfig = await this.keycloakService.getKcJsonStructure();
    this.keycloakInstance = Keycloak(keycloakConfig as KeycloakConfig);
  }

  private async initKeycloak() {
    await this.initKeycloakInstance();
    this.keycloakService.initKeycloak(this.keycloakInstance);
    return await this.keycloakInstance.init({
      adapter: 'default',
      redirectUri:
        this.infrastructure.environment.property_searcher_home_url + '/'
    });
  }

  public refresh(path: string): Promise<AuthToken> {
    try {
      void this.keycloakService.refresh();
    } catch (e) {
      this.logout(path);
      return null;
    }
  }

  public getAuthTokenFromKeycloakInstance(): AuthToken {
    return this.keycloakService.getAuthTokenFromKeycloakInstance();
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  public async continueLoginWithCode({
    code,
    redirectUri: redirectUrl
  }: KeycloakLoginResponse) {
    const uri = this.keycloakService.getTokenUrl();
    const body = this.keycloakService.getAccessTokenParams(code, redirectUrl);
    const headers = this.keycloakService.getTokenRequestHeaders();
    // eslint-disable-next-line @typescript-eslint/require-await
    return this.keycloakService.createPostRequest(uri, body, { headers }).then(
      // eslint-disable-next-line @typescript-eslint/require-await
      async authToken => {
        this.authTokenService.setToken(authToken);
        return authToken;
      }
    );
  }

  private beginLogin(options: KeycloakLoginOptions) {
    this.authTokenService.setToken(null);
    const url = this.keycloakInstance.createLoginUrl(options);
    this.windowRef.location.href = url;
  }
}
