import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import {
  CityPacket,
  Constants,
  InternationalizationSettings,
  Project,
  PropertySearcherUser,
  SearchProfile
} from '@ui/shared/models';

import * as fromBaseState from 'libs/infrastructure/base-state';
import { getUserData } from 'tenant-pool/+state/user/user.selectors';
import * as fromState from 'tenant-pool/+state/search-profiles';

import { DistrictHandlingService, SearchProfileService } from 'libs/services';

@UntilDestroy()
@Component({
  selector: 'app-search-profile-details-modal',
  templateUrl: './search-profile-details-modal.component.html',
  styleUrls: ['./search-profile-details-modal.component.scss'],
  providers: [SearchProfileService, DistrictHandlingService]
})
export class SearchProfileDetailsModalComponent implements OnInit {
  @Input() searchProfile: SearchProfile;
  public form: FormGroup;
  public landlordCityPacket: CityPacket;
  public onlyShowConfiguredCityPartsToUser: boolean;
  public project: Project;
  public processing$: Observable<boolean>;
  public completed$: Observable<boolean>;
  public constants$: Observable<Constants>;
  public userData$: Observable<PropertySearcherUser>;
  public itpLandlordCountries: InternationalizationSettings = {
    itpCountries: []
  };

  public get isNewSearchProfile() {
    return !this.searchProfile?.id;
  }

  constructor(
    private ngbActiveModal: NgbActiveModal,
    private store: Store<fromState.SearchProfilesState>,
    private searchProfileService: SearchProfileService,
    private districtHandlingService: DistrictHandlingService
  ) {}

  ngOnInit(): void {
    this.store
      .select(fromState.getSearchProfileProjectRegistrationProject)
      .pipe(untilDestroyed(this))
      .subscribe(project => {
        this.project = project || {
          id: this.searchProfile?.projectId,
          data: { ...this.searchProfile?.projectData }
        };
      });

    this.store
      .select(getUserData)
      .pipe(take(1))
      .subscribe(
        ({
          districtGroup,
          onlyShowConfiguredCityPartsToUser,
          customerBranding
        }) => {
          if (customerBranding?.countries) {
            customerBranding?.countries.map(country => {
              this.itpLandlordCountries.itpCountries.push(country);
            });
          }
          if (districtGroup)
            this.landlordCityPacket =
              this.districtHandlingService.cityPacketViewPayload([
                districtGroup
              ])[0];
          this.onlyShowConfiguredCityPartsToUser =
            onlyShowConfiguredCityPartsToUser;
        }
      );

    this.form = this.searchProfileService.searchProfileForm;

    this.processing$ = this.store
      .select(fromState.getSaveSearchProfileActionState)
      .pipe(map(state => state.pending));

    this.completed$ = this.store
      .select(fromState.getSaveSearchProfileActionState)
      .pipe(map(state => state.done));

    this.constants$ = this.store.select(fromBaseState.getConstants);
    this.userData$ = this.store.select(getUserData);
  }

  public dismiss() {
    this.ngbActiveModal.dismiss(true);
    this.store.dispatch(new fromState.UnloadProjectForProjectSearchProfile());
  }

  public save() {
    const payload = this.searchProfileService.extractSearchProfileDataPayload(
      this.form.value,
      this.searchProfile?.id
    );

    this.store.dispatch(new fromState.SaveSearchProfile(payload));
    this.store.dispatch(new fromState.UnloadProjectForProjectSearchProfile());

    this.completed$
      .pipe(
        filter(completed => !!completed),
        take(1)
      )
      .subscribe(() => {
        this.ngbActiveModal.close();
      });
  }
}
