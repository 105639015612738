import { ModuleWithProviders, NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { DirectivesModule } from 'libs/directives';
import { ComponentsModule } from 'libs/components';

import { selfDisclosureComponents } from './components';
import { SelfDisclosureService } from './self-disclosure.service';

const components = [...selfDisclosureComponents];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    DirectivesModule,
    ComponentsModule,
    AngularSvgIconModule
  ],
  declarations: [...components],
  exports: [...components]
})
export class SelfDisclosureModule {
  public static forRoot(): ModuleWithProviders<SelfDisclosureModule> {
    return {
      ngModule: SelfDisclosureModule,
      providers: [SelfDisclosureService]
    };
  }
}
