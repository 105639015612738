import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

import { BaseNavigationItem as NavigationItem } from '@ui/shared/models';

@Component({
  selector: 'app-main-navigation-item',
  templateUrl: './main-navigation-item.component.html',
  styleUrls: ['./main-navigation-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainNavigationItemComponent {
  @Input() item: NavigationItem;
  @Output() trackEvent = new EventEmitter<string>();
  public hover = false;

  public onTrackEvent(event?: string) {
    this.trackEvent.emit(event);
  }
}
