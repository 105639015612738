import { createSelector } from '@ngrx/store';
import { getServiceCardsState } from '../reducers';
import * as fromCardsReducers from './service-cards.reducer';

export const getServiceCards = createSelector(
  getServiceCardsState,
  fromCardsReducers.getServiceCards
);

export const getIsMilesCardAllowed = createSelector(
  getServiceCardsState,
  state => state.isMilesCardAllowed
);
