<div class="self-disclosure-address__content">
  <form [formGroup]="getFormControl">
    <div class="d-flex flex-row mt25 mb10">
      {{ selfDisclosureQuestion.title | translate }}
    </div>

    <div class="row mb5">
      <div class="col-6">
        <app-form-field>
          <app-form-field-label
            >{{ 'general.street_name' | translate }}</app-form-field-label
          >
          <input
            appInput
            type="text"
            [required]="selfDisclosureQuestion.mandatory"
            [placeholder]="'forms.type_street_name' | translate"
            formControlName="street"
            class="form-control"
          />
        </app-form-field>
      </div>
      <div class="col-6">
        <app-form-field>
          <app-form-field-label
            >{{ 'general.house_number_l' | translate }}</app-form-field-label
          >
          <input
            appInput
            type="text"
            [required]="selfDisclosureQuestion.mandatory"
            [placeholder]="'forms.type_housenumber' | translate"
            formControlName="houseNumber"
            class="form-control"
          />
        </app-form-field>
      </div>
    </div>

    <div class="row mb5">
      <div class="col-6">
        <app-form-field>
          <app-form-field-label
            >{{ 'general.zip_code_l' | translate }}</app-form-field-label
          >
          <input
            appInput
            type="text"
            [required]="selfDisclosureQuestion.mandatory"
            [placeholder]="'forms.type_zip_code' | translate"
            formControlName="zipCode"
            class="form-control"
          />
        </app-form-field>
      </div>
      <div class="col-6">
        <app-form-field>
          <app-form-field-label
            >{{ 'general.city_l' | translate }}</app-form-field-label
          >
          <input
            appInput
            type="text"
            [required]="selfDisclosureQuestion.mandatory"
            [placeholder]="'forms.type_city' | translate"
            formControlName="city"
            class="form-control"
          />
        </app-form-field>
      </div>
    </div>

    <div class="row mb5">
      <div class="col-12">
        <app-form-field>
          <app-form-field-label
            >{{ 'general.country' | translate }}</app-form-field-label
          >
          <input
            appInput
            type="email"
            [required]="selfDisclosureQuestion.mandatory"
            [placeholder]="'forms.type_country_l' | translate"
            formControlName="country"
            class="form-control"
          />
        </app-form-field>
      </div>
    </div>

    <div class="row mb5">
      <div class="col-6">
        <app-form-field>
          <app-form-field-label
            >{{ 'general.email_address_l' | translate }}</app-form-field-label
          >
          <input
            appInput
            type="email"
            [required]="selfDisclosureQuestion.mandatory"
            [placeholder]="'forms.type_email_address_l' | translate"
            formControlName="email"
            class="form-control"
          />
        </app-form-field>
      </div>
      <div class="col-6">
        <app-form-field>
          <app-form-field-label
            >{{ 'general.telephone_l' | translate }}</app-form-field-label
          >
          <app-phone-input
            formControlName="phone"
            [required]="selfDisclosureQuestion.mandatory"
          ></app-phone-input>
        </app-form-field>
      </div>
    </div>
  </form>
</div>
