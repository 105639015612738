<li
  class="nav-item d-flex"
  routerLinkActive="nav-item--active"
  #rla="routerLinkActive"
>
  <div class="curved-corner">
    <div class="curved-corner__bottomright--position">
      <div class="curved-corner__bottomright"></div>
    </div>
    <div class="curved-corner__topright--position">
      <div class="curved-corner__topright"></div>
    </div>
  </div>
  <a
    *ngIf="!item.redirectLink"
    class="nav-item__link d-flex align-items-center justify-content-between"
    routerLink="{{ item.link }}"
    (mouseenter)="hover = true"
    (mouseleave)="hover = false"
  >
    <div class="d-flex align-items-center">
      <svg-icon
        *ngIf="item.icon"
        src="/assets/images/icons/{{item.icon}}.svg"
        [applyClass]="true"
        [svgStyle]="{'width.px': 15}"
        class="default-svg-color path rect"
        routerLinkActive="black-svg-color"
        [class.white-svg-color]="hover && !rla.isActive"
      ></svg-icon>
      <span class="pl10">{{item.label | translate}}</span>
    </div>
    <div>
      <ng-content></ng-content>
    </div>
  </a>
  <a
    *ngIf="item.redirectLink"
    class="nav-item__link d-flex redirectLink"
    href="{{item.redirectLink}}"
    target="_blank"
  >
    {{item.label | translate}}
  </a>
</li>
