import { ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';

import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, switchMap, catchError, withLatestFrom } from 'rxjs/operators';

import * as fromBaseState from 'libs/infrastructure/base-state';
import { SessionStorageService } from 'libs/infrastructure';

import { PropertyFacade } from 'tenant-pool/core/services';
import { storageKeys } from 'tenant-pool/config';

import { MainPageNavigation } from 'tenant-pool/config';
import * as fromActions from './apply.actions';
import { getProperty } from './apply.selectors';

@Injectable()
export class ApplyEffects {
  constructor(
    private actions$: Actions,
    private propertyFacade: PropertyFacade,
    private store: Store<fromBaseState.BaseState>,
    private sessionStorage: SessionStorageService,
    private route: ActivatedRoute
  ) {}

  loadProperty$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.LoadProperty>(fromActions.LOAD_PROPERTY),
      switchMap(({ id }) =>
        this.propertyFacade.get(id).pipe(
          map(property => new fromActions.LoadPropertySuccess(property)),
          catchError(() => [
            new fromBaseState.Go({
              path: [
                MainPageNavigation.PROPERTIES,
                MainPageNavigation.PROPERTY_NOT_FOUND
              ]
            })
          ])
        )
      )
    )
  );

  apply$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.SetStorageForApply>(fromActions.SET_STORAGE_FOR_APPLY),
      withLatestFrom(
        this.store.select(fromBaseState.getRouterParams),
        this.store.select(fromBaseState.getRouterQueryParams),
        this.store.select(getProperty)
      ),
      map(([_, params, queryParams, property]) => {
        const {
          name,
          firstname: firstName,
          email
        } = this.route.snapshot.queryParams;
        if (queryParams.token) {
          this.sessionStorage.setItem(
            storageKeys.propertyTokenToApply,
            `${String(queryParams.token)}`
          );
        }

        if (name || firstName || email) {
          const personalInfo = {
            name,
            firstName,
            email
          };
          this.sessionStorage.setItem(
            storageKeys.personalInformation,
            personalInfo
          );
        }

        this.sessionStorage.setItem(
          storageKeys.propertyIdToApply,
          `${String(params.id)}`
        );
        this.sessionStorage.setItem(storageKeys.propertyData, property);

        return new fromActions.SetStorageForApplySuccess();
      })
    )
  );
}
