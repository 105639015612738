import { Injectable } from '@angular/core';

import { Apollo, QueryRef } from 'apollo-angular';

import { filter, map } from 'rxjs/operators';

import { Project, SearchProfile } from '@ui/shared/models';

import {
  deleteAllSearchProfilesMutation,
  deleteSearchProfileMutation,
  saveSearchProfileMutation,
  searchProfilesQuery,
  SearchProfilesQueryResponse,
  toggleSearchProfileMutation,
  deactivateAllSearchProfilesMutation,
  activateAllSearchProfilesMutation,
  ProjectQueryResponse,
  projectQuery
} from '../queries';

@Injectable()
export class SearchProfileFacade {
  private searchProfilesQuery: QueryRef<SearchProfilesQueryResponse>;
  private projectQuery: QueryRef<ProjectQueryResponse>;

  constructor(private apollo: Apollo) {}

  public loadSearchProfiles() {
    this.searchProfilesQuery =
      this.apollo.watchQuery<SearchProfilesQueryResponse>({
        query: searchProfilesQuery,
        fetchPolicy: 'cache-and-network'
      });

    return this.searchProfilesQuery.valueChanges.pipe(
      filter(response => !response.loading || !!response.data),
      map(response => response.data.searchProfiles)
    );
  }

  public save(searchProfile: SearchProfile) {
    return this.apollo.mutate({
      mutation: saveSearchProfileMutation,
      variables: { searchProfile },
      update: () => this.refetch()
    });
  }

  public delete(id: string) {
    return this.apollo.mutate({
      mutation: deleteSearchProfileMutation,
      variables: { id }
    });
  }

  public toggle(id: string) {
    return this.apollo.mutate({
      mutation: toggleSearchProfileMutation,
      variables: { id }
    });
  }

  public deleteAll() {
    return this.apollo.mutate({
      mutation: deleteAllSearchProfilesMutation,
      update: () => this.refetch()
    });
  }

  public deactivateAll(propertyType: string) {
    return this.apollo.mutate({
      mutation: deactivateAllSearchProfilesMutation,
      variables: {
        propertyType
      },
      update: () => this.refetch()
    });
  }

  public activateAll(propertyType: string) {
    return this.apollo.mutate({
      mutation: activateAllSearchProfilesMutation,
      variables: {
        propertyType
      },
      update: () => this.refetch()
    });
  }

  public searchProfileProjectRegistrationProject(token: string) {
    return this.apollo
      .query<{ project: Project }>({
        query: projectQuery,
        variables: { token },
        fetchPolicy: 'no-cache'
      })
      .pipe(map(response => response.data.project));
  }

  public refetch(): void {
    if (this.searchProfilesQuery) {
      void this.searchProfilesQuery.refetch();
    }
  }
}
