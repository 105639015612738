import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as fromAppReducers from 'tenant-pool/+state/reducers';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { MovingChecklistFacade } from 'tenant-pool/core/services';
import * as fromBaseState from 'libs/infrastructure/base-state';
import { notificationConfig as notification } from 'tenant-pool/config';
import * as fromActions from './moving-checklist.actions';

@Injectable()
export class MovingChecklistEffects {
  constructor(
    private actions$: Actions,
    private store: Store<fromAppReducers.AppState>,
    private facade: MovingChecklistFacade
  ) {}

  movingChecklistGetByProperty$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.MovingChecklistGetByProperty),
      switchMap(() =>
        this.facade.getMovingChecklistByPropertyID().pipe(
          map(
            movingChecklist =>
              fromActions.MovingChecklistGetByPropertySuccess({
                movingChecklist
              }),
            sessionStorage.removeItem('propertyIdToApply')
          ),
          catchError(error => [
            fromActions.MovingChecklistGetByPropertyFail({ error })
          ])
        )
      )
    )
  );

  setMoveInDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.MovingChecklistSetMoveInDate),
      switchMap(data =>
        this.facade.setMoveInDate(data).pipe(
          mergeMap(movingChecklist => [
            fromActions.MovingChecklistSetMoveInDateSuccess({
              movingChecklist
            }),
            new fromBaseState.ShowInfo(
              notification.movingChecklist.setMoveInDate.success
            )
          ]),
          catchError(error => [
            fromActions.MovingChecklistSetMoveInDateFail({ error })
          ])
        )
      )
    )
  );

  changeElement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.MovingChecklistChangeElement),
      switchMap(({ element }) =>
        this.facade.changeElement(element).pipe(
          mergeMap(movingChecklist => [
            fromActions.MovingChecklistChangeElementSuccess({
              movingChecklist
            }),
            new fromBaseState.ShowInfo(
              notification.movingChecklist.changeElement.success
            )
          ]),
          catchError(error => [
            fromActions.MovingChecklistChangeElementFail({ error })
          ])
        )
      )
    )
  );

  reset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.MovingChecklistReset),
      switchMap(() =>
        this.facade.reset().pipe(
          mergeMap(movingChecklist => [
            fromActions.MovingChecklistResetSuccess({
              movingChecklist
            }),
            new fromBaseState.ShowInfo(
              notification.movingChecklist.changeElement.success
            )
          ]),
          catchError(error => [fromActions.MovingChecklistResetFail({ error })])
        )
      )
    )
  );
}
