import { Component, Input } from '@angular/core';

import { FormGroup } from '@angular/forms';

import { SelfDisclosureQuestion } from '@ui/shared/models';

@Component({
  selector: 'app-self-disclosure-flat',
  templateUrl: './self-disclosure-flat.component.html',
  styleUrls: ['./self-disclosure-flat.component.scss']
})
export class SelfDisclosureFlatComponent {
  @Input() selfDisclosureQuestion: SelfDisclosureQuestion;
  @Input() form: FormGroup;

  public get getFormControl() {
    return this.form.get('answer') as FormGroup;
  }
}
