import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-apply-confirmation',
  templateUrl: './apply-confirmation.component.html',
  styleUrls: ['./apply-confirmation.component.scss']
})
export class ApplyConfirmationComponent {
  @Input() name: string;

  constructor(private ngbActiveModal: NgbActiveModal) {}

  close() {
    this.ngbActiveModal.close();
  }

  dismiss() {
    this.ngbActiveModal.dismiss();
  }
}
