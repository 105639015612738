import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { MovingAuctionForm } from '@ui/shared/models';
import {
  sendRequestMutation,
  SendRequestResponse
} from 'tenant-pool/core/queries/moving-auction.queries';

@Injectable()
export class MovingAuctionFacade {
  constructor(private apollo: Apollo) {}

  public sendRequest(movingAuctionForm: MovingAuctionForm) {
    return this.apollo
      .mutate<SendRequestResponse>({
        mutation: sendRequestMutation,
        variables: {
          movingAuctionForm
        }
      })
      .pipe(map(response => response.data.sendRequest));
  }
}
