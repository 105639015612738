<div class="self-disclosure-person__content">
  <form [formGroup]="form">
    <div class="d-flex flex-row mb10" [ngClass]="{'mt10': showRemove}">
      <span>{{ this.title | translate }}</span>
      <div *ngIf="showRemove" class="remove-attachment-button">
        <svg-icon
          src="/assets/images/icons/icon-bin.svg"
          [applyClass]="true"
          [svgStyle]="{'width.px': 15}"
          class="secondary-svg-color path rect"
          (click)="removeHandler()"
        ></svg-icon>
      </div>
    </div>

    <!-- Display salutation type subquestion -->
    <div
      *ngFor="let question of getSubQuestionsFormControl.controls; index as i"
    >
      <div
        [formGroup]="getQuestionsForm(i)"
        *ngIf="showSelectTypeQuestion(selfDisclosureQuestion.subQuestions[i])"
        class="col-12 col-lg-6 col-md-6 pl0 mb16"
      >
        <app-form-field>
          <app-form-field-label
            >{{ 'SALUTE_L' | translate }}</app-form-field-label
          >
          <app-select
            [required]="selfDisclosureQuestion.subQuestions[i].mandatory"
            [items]="getSelectTypeItems(selfDisclosureQuestion.subQuestions[i].constantName)"
            [placeholder]="'general.select_l' | translate"
            formControlName="answer"
          >
          </app-select>
        </app-form-field>
      </div>
    </div>

    <div [formGroup]="getAnswerFormControl">
      <div class="row mb5">
        <div class="col-6">
          <app-form-field>
            <app-form-field-label
              >{{ 'general.first_name_l' | translate }}</app-form-field-label
            >
            <input
              appInput
              type="text"
              [placeholder]="'forms.type_firstname' | translate"
              formControlName="firstName"
              [required]="selfDisclosureQuestion.mandatory"
              class="form-control"
            />
          </app-form-field>
        </div>
        <div class="col-6">
          <app-form-field>
            <app-form-field-label
              >{{ 'general.last_name_l' | translate }}</app-form-field-label
            >
            <input
              appInput
              type="text"
              [required]="selfDisclosureQuestion.mandatory"
              [placeholder]="'forms.type_lastname' | translate"
              formControlName="lastName"
              class="form-control"
            />
          </app-form-field>
        </div>
      </div>

      <div class="row mb5">
        <div class="col-6">
          <app-form-field>
            <app-form-field-label
              >{{ 'general.birthname_l' | translate }}</app-form-field-label
            >
            <input
              appInput
              type="text"
              [placeholder]="'forms.type_birthname' | translate"
              formControlName="birthName"
              class="form-control"
            />
          </app-form-field>
        </div>
        <div class="col-6">
          <app-form-field>
            <app-form-field-label
              >{{ 'general.birthplace_l' | translate }}</app-form-field-label
            >
            <input
              appInput
              type="text"
              [required]="selfDisclosureQuestion.mandatory"
              [placeholder]="'forms.type_birthplace' | translate"
              formControlName="birthPlace"
              class="form-control"
            />
          </app-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <app-form-field>
            <app-form-field-label
              >{{ 'general.birthdate_l' | translate }}</app-form-field-label
            >
            <app-date
              [required]="selfDisclosureQuestion.mandatory"
              formControlName="birthDate"
              [startDate]="startDateStruct"
              [maxDate]="maxDateStruct"
              [minDate]="minDateStruct"
            ></app-date>
          </app-form-field>
        </div>
      </div>

      <!-- display all non-salutation subQuestion -->
      <div>
        <div
          *ngFor="let question of getSubQuestionsFormControl.controls; index as i"
        >
          <ng-container
            [formGroup]="getQuestionsForm(i)"
            *ngIf="isOtherSubQuestion(selfDisclosureQuestion.subQuestions[i])"
            class="mb16"
          >
            <div
              *ngIf="selfDisclosureQuestion.subQuestions[i].showSelfDisclosureQuestions && isTypeBooleanOrSelect(selfDisclosureQuestion.subQuestions[i])"
              class="mt20"
            >
              <app-form-field>
                <app-form-field-label
                  >{{ selfDisclosureQuestion.subQuestions[i].title | translate
                  }}</app-form-field-label
                >
                <app-flat-select
                  formControlName="answer"
                  [items]="options(selfDisclosureQuestion.subQuestions[i])"
                  [required]="selfDisclosureQuestion.subQuestions[i].mandatory"
                  [multiple]="false"
                ></app-flat-select>
              </app-form-field>

              <div *ngIf="showHint(i)">
                <app-form-field>
                  <app-form-field-label
                    >{{ selfDisclosureQuestion.subQuestions[i].commentHint |
                    translate }}</app-form-field-label
                  >
                  <textarea
                    appInput
                    count="true"
                    maxValue="1000"
                    formControlName="comment"
                    class="form-control"
                  ></textarea>
                </app-form-field>
              </div>
            </div>
          </ng-container>

          <div>
            <div
              *ngIf="selfDisclosureQuestion.subQuestions[i].showSelfDisclosureQuestions && isEmployment(selfDisclosureQuestion.subQuestions[i])"
              class="mt20"
            >
              <app-self-disclosure-employment
                [form]="getSubQuestionForm(i)"
                [selfDisclosureQuestion]="selfDisclosureQuestion.subQuestions[i]"
              ></app-self-disclosure-employment>
            </div>

            <div
              *ngIf="selfDisclosureQuestion.subQuestions[i].showSelfDisclosureQuestions && isAddress(selfDisclosureQuestion.subQuestions[i])"
              class="mt20"
            >
              <app-self-disclosure-address
                [form]="getSubQuestionForm(i)"
                [selfDisclosureQuestion]="selfDisclosureQuestion.subQuestions[i]"
              ></app-self-disclosure-address>
            </div>

            <div
              *ngIf="selfDisclosureQuestion.subQuestions[i].showSelfDisclosureQuestions && isDocument(selfDisclosureQuestion.subQuestions[i])"
              class="mt20"
            >
              <app-self-disclosure-document
                [form]="getSubQuestionForm(i)"
                [selfDisclosureQuestion]="selfDisclosureQuestion.subQuestions[i]"
              ></app-self-disclosure-document>
            </div>
          </div>
        </div>
      </div>

      <div
        *ngIf="getConfirmationsFormControl && getConfirmationsFormControl.controls.length > 0"
        class="mt20 mb20"
      >
        <div class="d-flex flex-row mb10">
          {{'self_disclosure.answers.confirm_l' | translate}}
          <span class="required-marker ng-star-inserted">&nbsp;*</span>
        </div>
        <div
          *ngFor="let confirm of getConfirmationsFormControl.controls; index as i"
        >
          <div
            [formGroup]="confirm"
            [ngClass]="{'invalid': confirm.invalid && confirm.touched}"
          >
            <app-form-field>
              <app-checkbox
                required
                [label]="confirm.value.text | translate"
                formControlName="checked"
              ></app-checkbox>
            </app-form-field>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
