import { Injectable } from '@angular/core';

import { Apollo } from 'apollo-angular';

import { map } from 'rxjs/operators';

import {
  AnonymousApplicationPayload,
  GqlResponseStatus,
  DeclareIntentData,
  ReasonData
} from '@ui/shared/models';

import {
  ApplicationsResult,
  removeApplicationMutation,
  createApplicationMutation,
  declareIntentMutation,
  denyIntentMutation,
  rentedFlatsApplicationsQuery,
  RentedFlatsApplicationsQueryResult,
  RemoveApplicationMutationResponse,
  CreateApplicationMutationResponse,
  findAvailableForNewConversationQuery,
  checkApplicationExistQuery,
  CheckApplicationExistResult,
  applyAnonymouslyMutation,
  revokeAllOpenApplications,
  checkIdentityMutation
} from '../queries';

import { PropertyMatchesFacade } from './property-matches.facade';

@Injectable()
export class ApplicationFacade {
  constructor(
    private apollo: Apollo,
    private propertyMatchesFacade: PropertyMatchesFacade
  ) {}

  public removeApplication(reasonData: ReasonData, rented?: boolean) {
    return this.apollo
      .mutate<RemoveApplicationMutationResponse>({
        mutation: removeApplicationMutation,
        variables: { reasonData },
        update: () => this.updateQueries(rented)
      })
      .pipe(map(response => response?.data?.removeApplication?.id));
  }

  public loadRentedFlatsApplications() {
    return this.apollo
      .query<RentedFlatsApplicationsQueryResult>({
        query: rentedFlatsApplicationsQuery,
        fetchPolicy: 'no-cache'
      })
      .pipe(map(response => response?.data?.rentedFlatsApplications));
  }

  public checkApplicationExist(propertyId: string) {
    return this.apollo
      .query<CheckApplicationExistResult>({
        query: checkApplicationExistQuery,
        variables: { propertyId },
        fetchPolicy: 'no-cache'
      })
      .pipe(map(response => response?.data?.checkApplicationExist?.id));
  }

  public checkIdentity(token: string) {
    return this.apollo.mutate({
      mutation: checkIdentityMutation,
      variables: {
        token
      }
    });
  }

  public revokeAllOpenApplications() {
    return this.apollo.mutate({
      mutation: revokeAllOpenApplications,
      variables: {
        input: {
          refusalReasonType: 'DONT_SEARCH_ANYMORE'
        }
      },
      update: () => this.updateQueries()
    });
  }

  public createApplication(userId: string, propertyId: string, token?: string) {
    return this.apollo
      .mutate<CreateApplicationMutationResponse>({
        mutation: createApplicationMutation,
        variables: { propertyId, userId, token: `${token}` },
        update: () => this.updateQueries()
      })
      .pipe(map(response => response?.data?.createApplication));
  }

  public applyAnonymously(payload: AnonymousApplicationPayload) {
    return this.apollo.mutate<GqlResponseStatus>({
      mutation: applyAnonymouslyMutation,
      variables: { payload }
    });
  }

  public declareIntent(declareIntentData: DeclareIntentData) {
    return this.apollo.mutate({
      mutation: declareIntentMutation,
      variables: { declareIntentData }
    });
  }

  public findAvailableForNewConversation(userId: string) {
    return this.apollo.query<ApplicationsResult>({
      query: findAvailableForNewConversationQuery,
      variables: {
        userId
      },
      fetchPolicy: 'no-cache'
    });
  }

  public denyIntent(declareIntentData: DeclareIntentData, token: string) {
    return this.apollo.mutate({
      mutation: denyIntentMutation,
      variables: { declareIntentData, token }
    });
  }

  public updateQueries(rented?: boolean) {
    if (rented) {
      this.propertyMatchesFacade.refetchRentedMatches();
    } else {
      this.propertyMatchesFacade.refetchMatches();
    }
  }
}
