import { Component, forwardRef, Input } from '@angular/core';

import { FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

import { SelfDisclosureQuestion } from '@ui/shared/models';

import { BaseControl } from 'libs/components/legacy/form';

@Component({
  selector: 'app-self-disclosure-employment',
  templateUrl: './self-disclosure-employment.component.html',
  styleUrls: ['./self-disclosure-employment.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelfDisclosureEmploymentComponent),
      multi: true
    }
  ]
})
export class SelfDisclosureEmploymentComponent extends BaseControl<any> {
  @Input() selfDisclosureQuestion: SelfDisclosureQuestion;
  @Input() form: FormGroup;

  public writeValue(value: any) {
    this.value = value ? value : [];
  }

  public get getFormControl() {
    return this.form.get('answer') as FormGroup;
  }
}
