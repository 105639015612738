<div class="row container-padding">
  <div class="col-12 col-xl-8">
    <ng-container *ngIf="deliveryData.postDelivery || hasError;else uploadArea">
      <app-heading level="2" styleType="100"
        >{{ 'schufa.finish_post_title_l' | translate }}</app-heading
      >
      <div
        *ngIf="!hasError;else error"
        [innerHTML]="'schufa.finish_post_description_l' | translate "
      ></div>
      <ng-template #error>
        <div
          [innerHTML]="'schufa.finish_failed_indentify_post_description_l' | translate "
        ></div>
      </ng-template>
      <app-button
        *ngIf="isLoggedIn;else loginBtn"
        class="mt15 mb10 d-inline-block"
        [type]="'primary'"
        (clickEvent)="onFinish()"
        >{{ 'schufa.finish.ok_a' | translate }}</app-button
      >
      <ng-template #loginBtn>
        <app-button
          class="mt15 d-inline-block"
          [type]="'primary'"
          (clickEvent)="onLogin()"
          >{{'schufa.login_now_a' | translate}}</app-button
        >
      </ng-template>
    </ng-container>
    <ng-template #uploadArea>
      <div class="schufa__header pb20 mb20">
        <app-heading level="2" styleType="100"
          >{{ 'schufa.finish_download_title_l' | translate }}</app-heading
        >
        <div>{{ 'schufa.finish_download_message_l' | translate }}</div>
      </div>
      <div class="schufa__body pb20 mb20">
        <div class="schufa__credit-report pb10 mb20">
          <div><strong>{{ 'schufa.credit_report_l' | translate }}</strong></div>
          <app-button
            [type]="'primary'"
            class="mb10 d-inline-block"
            (clickEvent)="onDownloadCreditReportPDF(deliveryResponse?.oneTimeURL)"
            >{{ 'schufa.credit_report_a' | translate }}</app-button
          >
          <div class="schufa__download-info d-flex row mr0 ml0 mb10">
            <div class="col-12 col-md-4 p0">
              {{ 'schufa.verify_code_l' | translate }}
            </div>
            <div class="col-12 col-md-4">
              <strong>{{ deliveryResponse?.verifyCode }}</strong>
            </div>
          </div>
          <div class="schufa__download-info d-flex row mr0 ml0 mb20">
            <div class="col-12 col-md-4 p0">
              {{ 'schufa.verify_url_l' | translate }}
            </div>
            <div class="col-12 col-md-4">
              <a target="_blank" [href]="deliveryResponse?.verifyURL"
                >{{ deliveryResponse?.verifyURL }}</a
              >
            </div>
          </div>
          <div
            class="col-12 p0"
            [innerHTML]="'schufa.additional_info_l' | translate"
          ></div>
        </div>
        <div class="schufa__upload" *ngIf="isLoggedIn;else loginMessage">
          <form [formGroup]="form">
            <app-heading level="3" styleType="200"
              >{{ 'schufa.finish_upload_title_l' | translate }}</app-heading
            >
            <div>
              {{ 'schufa.upload_credit_report_description_l' | translate }}
            </div>
            <div class="mb12">
              <app-form-field>
                <app-form-field-label
                  >{{ 'general.credit_score_l' | translate
                  }}</app-form-field-label
                >
                <app-attachments
                  [showRemove]="true"
                  [isDocument]="true"
                  formControlName="creditReport"
                ></app-attachments>
              </app-form-field>
            </div>
            <div class="p0 upload-button-container">
              <app-button
                [type]="'primary'"
                (clickEvent)="onUploadCreditReport()"
                [disabled]="form.invalid"
                >{{'schufa.upload_credit_report_l' | translate}}</app-button
              >
            </div>
          </form>
        </div>
        <ng-template #loginMessage>
          <div class="not-logged-in">
            <app-heading level="3" styleType="200"
              >{{ 'schufa.finish_login_title_l' | translate }}</app-heading
            >
            <div class="mb20">
              {{ 'schufa.finish_login_message_l' | translate }}
            </div>
            <div class="login d-inline-block">
              <app-button [type]="'primary'" (clickEvent)="onLogin()"
                >{{'schufa.login_now_a' | translate}}</app-button
              >
            </div>
          </div>
        </ng-template>
      </div>
    </ng-template>
  </div>
</div>
