<div *ngIf="!isSigned && !hasError">
  <span
    >{{'digital_contract.wizard_sign.redirect_docusign_l' | translate}}</span
  >

  <div class="mt30 loading-area">
    <app-loading-spinner></app-loading-spinner>
  </div>
</div>

<div *ngIf="hasError" class="mt40 docusign-error">
  <span
    >{{'digital_contract.wizard_sign.redirect_docusign_error_l' |
    translate}}</span
  >
</div>

<div *ngIf="isSigned && !hasError">
  <h3>
    {{'digital_contract.wizard_sign.signature_done_header_l' | translate}}
  </h3>
  <span
    >{{'digital_contract.wizard_sign.signature_done_text_l' | translate}}</span
  >

  <div *ngIf="showShopCard" class="mt40 shop__container pb-4">
    <a href="https://umzugsshop.immomio.com" target="_blank">
      <img
        src="{{ narrowScreen ? '/assets/images/umzugsshop_promo_mobile.svg' : '/assets/images/umzugsshop_promo.svg' }}"
      />
    </a>
  </div>
</div>
