import moment from 'moment';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';

import {
  CustomQuestion,
  PropertyMatchBean,
  QuestionContainer,
  RootQuestion
} from '@ui/shared/models';
import * as fromBaseState from 'libs/infrastructure/base-state';
import { LocalStorageService } from 'libs/infrastructure';
import { HierarchicalQuestionService } from 'libs/services/hierarchical-question.service';
import { customQuestionsPayloadHelper } from 'libs/utils/custom-questions-payload-helper';

import * as fromActions from 'tenant-pool/+state/custom-questions/custom-questions.actions';
import * as fromSelectors from 'tenant-pool/+state/custom-questions/custom-questions.selectors';
import { CustomQuestionsState } from 'tenant-pool/+state/custom-questions/custom-questions.reducers';
import { NAVIGATION_LINK, storageKeys } from 'tenant-pool/config';
import {
  LoadPropertyMatchBean,
  OpenCompleteModal
} from 'tenant-pool/+state/property-matches/property-matches.actions';
import { getUserData } from 'tenant-pool/+state';
import { areObjectsEqual } from 'libs/utils';
import { ButtonTypeEnum } from 'libs/components/atoms/button/button.enum';

@Component({
  selector: 'app-custom-questions-modal',
  templateUrl: './custom-questions-modal.component.html',
  styleUrls: ['./custom-questions-modal.component.scss']
})
export class CustomQuestionsModalComponent implements OnInit {
  public control = new FormControl();
  public hierarchicalRootQuestionControl = new FormControl();
  public isProcessing$: Observable<boolean>;
  public questionContainer: QuestionContainer;
  public propertyMatchBean: PropertyMatchBean;
  public customQuestions: CustomQuestion[];
  public rootQuestions: RootQuestion[];
  public hierarchicalQuestionErrorMessage$: Observable<string>;

  public hideAnsweredQuestions = false;
  public baselineQuestions: RootQuestion[];

  public openSecondModalForGarageProperties = false;

  constructor(
    private ngbActiveModal: NgbActiveModal,
    private store: Store<CustomQuestionsState>,
    private hierarchicalQuestionService: HierarchicalQuestionService,
    private localStorageService: LocalStorageService
  ) {}

  public ngOnInit(): void {
    this.store.dispatch(
      new fromActions.SetHierarchicalQuestionsModalOpen(true)
    );
    this.customQuestions = this.questionContainer.customQuestions.map(cq => {
      return cq.selectedRange
        ? {
            ...cq,
            selectedRange: moment(cq.selectedRange).format('YYYY-MM-DD')
          }
        : cq;
    });

    this.isProcessing$ = combineLatest([
      this.store.select(fromSelectors.getCustomQuestionLoading),
      this.store.select(fromSelectors.getSaveAnswersActionState)
    ]).pipe(map(([loading, state]) => loading || state.pending));

    this.hierarchicalQuestionErrorMessage$ = this.store.select(
      fromSelectors.getCustomQuestionErrorMessage
    );

    this.rootQuestions = this.questionContainer.questions.map(
      q => q.rootQuestion
    );

    this.store
      .select(getUserData)
      .pipe(take(1))
      .subscribe(userData => {
        if (userData.profile.dateOfBirth) {
          // if user maintains dateOfBirth, then set it as the default value for the birthdate question
          // unless the user has already answered the birthDate question, then keep as it is
          this.rootQuestions = this.rootQuestions.map(q => ({
            ...q,
            questions: q.questions.map(item => {
              if (item.data?.idType === 'BIRTHDATE' && !item.userResponse) {
                return {
                  ...item,
                  userResponse: {
                    response: userData.profile.dateOfBirth
                  }
                };
              }
              return item;
            })
          }));
        }
      });

    this.control.patchValue(this.customQuestions);
    this.hierarchicalRootQuestionControl.patchValue(this.rootQuestions);
  }

  public onFormValidityChange(isValid: boolean): void {
    this.control.setErrors(isValid ? null : { missingFields: true });
  }

  public onHierarchicalFormValidityChange(isValid: boolean): void {
    this.hierarchicalRootQuestionControl.setErrors(
      isValid ? null : { missingFields: true }
    );
  }

  public dismiss(): void {
    this.store.dispatch(
      new fromActions.SetHierarchicalQuestionsModalOpen(false)
    );
    this.localStorageService.removeItem(
      storageKeys.customQuestionApplicationId
    );
    this.ngbActiveModal.dismiss();

    if (this.openSecondModalForGarageProperties)
      this.openCompleteProfileModal();
  }

  private mergeAnsweredQuestionsWithBaseline() {
    const updatedQuestions = this.hierarchicalRootQuestionControl
      .value as RootQuestion[];

    updatedQuestions.forEach(question => {
      const baselineQuestion = this.baselineQuestions.find(
        base => base.id === question.id
      );

      this.baselineQuestions = this.baselineQuestions.map(base => {
        if (
          base.id === question.id &&
          !areObjectsEqual(baselineQuestion, question)
        ) {
          return { ...question };
        } else {
          return base;
        }
      });
    });

    return this.baselineQuestions;
  }

  public save(): void {
    if (!this.control.valid) return;

    const payload = customQuestionsPayloadHelper(
      this.control.value,
      this.customQuestions
    );
    const hierarchicalRootQuestions =
      this.hierarchicalQuestionService.getHierarchicalRootQuestionPayload(
        this.hideAnsweredQuestions
          ? this.mergeAnsweredQuestionsWithBaseline()
          : this.hierarchicalRootQuestionControl.value
      );
    this.store.dispatch(
      new fromActions.HierarchicalQuestionResponse(hierarchicalRootQuestions)
    );
    this.store.dispatch(new fromActions.SaveCustomQuestionsAnswers(payload));

    this.store
      .select(fromSelectors.getSaveAnswersActionState)
      .pipe(
        filter(state => !!state.done),
        switchMap(() =>
          this.store.select(fromSelectors.getCustomQuestionActionState).pipe(
            filter(state => !state.pending),
            withLatestFrom(this.hierarchicalQuestionErrorMessage$),
            take(1),
            filter(([_, errorMsg]) => !errorMsg)
          )
        )
      )
      .subscribe(() => {
        if (this.propertyMatchBean) {
          this.store.dispatch(
            LoadPropertyMatchBean({ id: this.propertyMatchBean.id })
          );
          // When openSecondModalForGarageProperties is true, then we are already on PROPERTIES_APPLICATIONS
        } else if (!this.openSecondModalForGarageProperties) {
          // if propertyMatch is not present that means we are opening the modal with customer Id
          // we navigate back to the applications page to clear the query params
          // so users won't be getting the modal again on reload
          this.store.dispatch(
            new fromBaseState.Go({
              path: [NAVIGATION_LINK.PROPERTIES_APPLICATIONS]
            })
          );
        }
        this.store.dispatch(
          new fromActions.SetHierarchicalQuestionsModalOpen(false)
        );
        this.localStorageService.removeItem(
          storageKeys.customQuestionApplicationId
        );
        this.ngbActiveModal.close();

        if (this.openSecondModalForGarageProperties)
          this.openCompleteProfileModal();
      });
  }

  /**
   * This is getting called when a (un-)registered PS applies to a garage
   */
  public openCompleteProfileModal() {
    this.store.dispatch(OpenCompleteModal({ showGenericModal: true }));
  }

  protected readonly ButtonTypeEnum = ButtonTypeEnum;
}
