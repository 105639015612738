<form [formGroup]="formGroup" class="payment">
  <div class="payment__main-header">
    <span>{{ 'rent_deposit.payment.title_l' | translate }}</span>
  </div>

  <div class="row">
    <div class="col-12">
      <span>{{ 'rent_deposit.payment.description_m' | translate }}</span>
    </div>
  </div>

  <div class="row pl15 pr15">
    <div class="col-12 payment__explanation">
      <span>{{ 'rent_deposit.payment.explanation_m' | translate }}</span>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <app-form-field>
        <app-flat-select
          [items]="paymentOptions"
          required
          formControlName="selectedPaymentOption"
        >
        </app-flat-select>
      </app-form-field>
    </div>
  </div>

  <div formGroupName="bankData">
    <div class="payment__sub-header">
      <span>{{ 'rent_deposit.payment.bank_title_l' | translate }}</span>
    </div>

    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <app-form-field>
          <app-form-field-label
            >{{'general.iban_l' | translate}}</app-form-field-label
          >
          <input
            appInput
            type="text"
            required
            [placeholder]="'forms.type_iban' | translate"
            formControlName="iban"
            class="form-control"
          />
        </app-form-field>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <app-form-field>
          <app-form-field-label
            >{{'general.bank_name_l' | translate}}</app-form-field-label
          >
          <input
            appInput
            type="text"
            required
            [placeholder]="'forms.type_name' | translate"
            formControlName="bankName"
            class="form-control"
          />
        </app-form-field>
      </div>
    </div>
  </div>

  <div formGroupName="deliveryData">
    <div class="payment__sub-header">
      <span>{{ 'rent_deposit.payment.delivery_title_l' | translate }}</span>
    </div>

    <div class="row">
      <div class="col-12">
        <app-form-field>
          <app-flat-select
            [items]="deliveryOptions"
            [allowUncheck]="true"
            required
            formControlName="selectedDeliveryOption"
          >
          </app-flat-select>
        </app-form-field>
      </div>
    </div>

    <div *ngIf="sendDocumentsToLandlord" class="row">
      <div class="col-12 d-flex justify-content-center payment__sent-to">
        {{'rent_deposit.payment.delivery_landlord_hint_l' | translate}}
      </div>
    </div>

    <div *ngIf="sendToTenant" class="row">
      <div class="col-12 d-flex justify-content-center payment__sent-to">
        {{'rent_deposit.payment.delivery_tenant_hint_l' | translate}}
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12 p0">
      <!--   rent_deposit.complete_a   -->
      <app-wizard-footer
        [disabled]="formGroup.invalid"
        [hasNext]="true"
        [hasPrevious]="true"
        (nextStep)="nextStep()"
        (previousStep)="previousStep()"
      >
      </app-wizard-footer>
    </div>
  </div>
</form>
