<div class="moving-auction-data container-padding">
  <div class="row mb20">
    <div class="col-12">
      <span class="moving-auction-data__title"
        >{{ 'moving_auction.wizard_data.title' | translate }}</span
      >
    </div>
  </div>
  <div class="container moving-auction-data__gutter">
    <div class="row moving-auction-data__gutter">
      <div class="moving-auction-data__card col">
        <div class="moving-auction-data__illustration">
          <div class="title-m">Von {{ address?.currentAddress?.city}}</div>
          <div class="moving-auction-data__lines">
            <div
              class="moving-auction-data__lines--mid-horizontal moving-auction-data__lines--grow-4"
            ></div>
            <div class="moving-auction-data__lines--mid-vertical"></div>
            <div
              class="moving-auction-data__lines--bottom-horizontal moving-auction-data__lines--grow-1"
            ></div>
          </div>
          <img src="/assets/images/umzugsauktion_transporter.svg" />
        </div>

        <form [formGroup]="start">
          <div class="row mb30">
            <div class="col-12">
              <div
                class="moving-auction-data__address-information"
                innerHTML="{{ address?.currentAddress | appAddressVertical}}"
              ></div>
            </div>
          </div>

          <div class="row mb20">
            <div class="col-6">
              <app-form-field>
                <app-form-field-label
                  >{{'moving_auction.wizard_data.start_option_object_type' |
                  translate}}</app-form-field-label
                >
                <app-select
                  [items]="OBJECT_TYPES"
                  [placeholder]="'' | translate"
                  formControlName="residenceType"
                >
                </app-select>
              </app-form-field>
            </div>

            <div class="col-6">
              <app-form-field>
                <app-form-field-label
                  >{{'moving_auction.wizard_data.start_living_space' |
                  translate}}</app-form-field-label
                >
                <input
                  appInput
                  type="number"
                  required
                  [placeholder]="'forms.type_area' | translate"
                  formControlName="livingSpace"
                  class="form-control"
                />
              </app-form-field>
            </div>
          </div>

          <div
            class="row"
            [class.d-none]="residenceTypeStart.value === 'HOUSE'"
          >
            <div class="col-12 col-xl-6 mb20">
              <app-form-field>
                <app-form-field-label
                  >{{'moving_auction.wizard_data.start_option_floor' |
                  translate}}</app-form-field-label
                >
                <app-select
                  [items]="FLOOR_OPTIONS"
                  [placeholder]="'' | translate"
                  formControlName="floor"
                >
                </app-select>
              </app-form-field>
            </div>
            <div class="col-12 col-xl-6 mb20">
              <app-form-field>
                <app-form-field-label
                  >{{ 'moving_auction.wizard_data.start_option_elevator' |
                  translate}}</app-form-field-label
                >
                <app-switch
                  class="moving-auction-data__switch"
                  [config]="BOOLEAN_OPTIONS"
                  selectedItem="1"
                  formControlName="elevator"
                >
                </app-switch>
              </app-form-field>
            </div>
          </div>

          <div class="row mb20">
            <div class="col-12">
              <app-form-field>
                <app-form-field-label
                  >{{'moving_auction.wizard_data.number_of_people' |
                  translate}}</app-form-field-label
                >
                <app-slider
                  [min]="1"
                  [max]="7"
                  [step]="1"
                  [unitMax]="'+'"
                  formControlName="residents"
                >
                </app-slider>
              </app-form-field>
            </div>
          </div>

          <div class="row mb20">
            <div class="col-12">
              <app-form-field>
                <app-form-field-label
                  >{{'moving_auction.wizard_data.number_of_rooms' |
                  translate}}</app-form-field-label
                >
                <app-slider
                  [min]="1"
                  [max]="7"
                  [step]="0.5"
                  [unitMax]="'+'"
                  formControlName="rooms"
                >
                </app-slider>
              </app-form-field>
            </div>
          </div>

          <hr class="mb30" />

          <div formGroupName="bookedServices">
            <div class="row mb20">
              <div class="col-12">
                <span class="moving-auction-data__sub-title"
                  >{{ 'moving_auction.wizard_data.start_booked_services' |
                  translate }}</span
                >
              </div>
            </div>

            <div class="row mb20">
              <div class="col-12">
                <app-form-field>
                  <app-form-field-label
                    >{{ 'moving_auction.wizard_data.no_parking_zone' |
                    translate}}</app-form-field-label
                  >
                  <app-select
                    [items]="PARKING_OPTIONS"
                    [placeholder]="'' | translate"
                    formControlName="noParkingZone"
                  >
                  </app-select>
                </app-form-field>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="moving-auction-data__card col">
        <div class="moving-auction-data__illustration">
          <div class="title-m">Nach {{ address?.newAddress?.city }}</div>
          <div class="moving-auction-data__lines">
            <div
              class="moving-auction-data__lines--mid-horizontal moving-auction-data__lines--grow-1"
            ></div>
            <div class="moving-auction-data__lines--mid-vertical"></div>
            <div
              class="moving-auction-data__lines--bottom-horizontal moving-auction-data__lines--grow-4"
            ></div>
          </div>
          <img src="/assets/images/umzugsauktion_haus.svg" />
        </div>
        <form [formGroup]="destination">
          <div class="row mb30">
            <div class="col-12">
              <div
                class="moving-auction-data__address-information"
                innerHTML="{{ address?.newAddress | appAddressVertical}}"
              ></div>
            </div>
          </div>

          <div *ngIf="!hasExactDestinationAddress" class="row mb30 pt30">
            <div class="col-12">
              <app-form-field>
                <app-checkbox
                  [formControl]="detailsNotKnown"
                  [label]="'moving_auction.wizard_data.details_not_known_l' | translate"
                ></app-checkbox>
              </app-form-field>
            </div>
          </div>

          <div class="row mb20">
            <div class="col-6">
              <app-form-field>
                <app-form-field-label
                  >{{'moving_auction.wizard_data.destination_option_object_type'
                  | translate}}</app-form-field-label
                >
                <app-select
                  [items]="OBJECT_TYPES"
                  [placeholder]="'' | translate"
                  formControlName="residenceType"
                >
                </app-select>
              </app-form-field>
            </div>
          </div>

          <div
            class="row"
            [class.d-none]="residenceTypeDestination.value === 'HOUSE'"
          >
            <div class="col-12 col-xl-6 mb20">
              <app-form-field>
                <app-form-field-label
                  >{{'moving_auction.wizard_data.destination_option_floor' |
                  translate}}</app-form-field-label
                >
                <app-select
                  [items]="FLOOR_OPTIONS"
                  [placeholder]="'' | translate"
                  formControlName="floor"
                >
                </app-select>
              </app-form-field>
            </div>
            <div class="col-12 col-xl-6 mb20">
              <app-form-field>
                <app-form-field-label
                  >{{ 'moving_auction.wizard_data.destination_option_elevator' |
                  translate}}</app-form-field-label
                >
                <app-switch
                  class="moving-auction-data__switch"
                  [config]="BOOLEAN_OPTIONS"
                  selectedItem="1"
                  formControlName="elevator"
                >
                </app-switch>
              </app-form-field>
            </div>
          </div>

          <hr class="mb30" />

          <div formGroupName="bookedServices">
            <div class="row mb20">
              <div class="col-12">
                <span class="moving-auction-data__sub-title"
                  >{{ 'moving_auction.wizard_data.destination_booked_services' |
                  translate }}</span
                >
              </div>
            </div>

            <div class="row mb20">
              <div class="col-12">
                <app-form-field>
                  <app-form-field-label
                    >{{ 'moving_auction.wizard_data.no_parking_zone' |
                    translate}}</app-form-field-label
                  >
                  <app-select
                    [items]="PARKING_OPTIONS"
                    [placeholder]="'' | translate"
                    formControlName="noParkingZone"
                  >
                  </app-select>
                </app-form-field>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="row moving-auction-data__gutter">
      <div class="moving-auction-data__card col">
        <form [formGroup]="movingDates">
          <div class="row mb20">
            <div class="col-12">
              <span class="moving-auction-data__sub-title"
                >{{ 'moving_auction.wizard_data.dates_subtitle' | translate
                }}</span
              >
            </div>
          </div>

          <div class="row mb20">
            <div class="col-12 col-xl-4">
              <app-form-field>
                <app-form-field-label
                  >{{'moving_auction.wizard_data.dates_option_time_type' |
                  translate}}</app-form-field-label
                >
                <app-select
                  [items]="TIME_TYPE_OPTIONS"
                  formControlName="relocationTimeType"
                >
                </app-select>
              </app-form-field>
            </div>

            <div class="col-6 col-xl-4">
              <app-form-field>
                <app-form-field-label
                  >{{(isExactMovingDate ?
                  'moving_auction.wizard_data.dates_moving_exact' : isStorage ?
                  'moving_auction.wizard_data.dates_moving_storage_from' :
                  'moving_auction.wizard_data.dates_moving_earliest') |
                  translate}}</app-form-field-label
                >
                <app-date
                  [required]="true"
                  [minDate]="minDayToday"
                  formControlName="movingEarliest"
                >
                </app-date>
              </app-form-field>
            </div>

            <div class="col-6 col-xl-4" *ngIf="!isExactMovingDate">
              <app-form-field>
                <app-form-field-label
                  >{{(isStorage ?
                  'moving_auction.wizard_data.dates_moving_storage_to' :
                  'moving_auction.wizard_data.dates_moving_latest') |
                  translate}}</app-form-field-label
                >
                <app-date
                  [required]="true"
                  [minDate]="minLatestDay"
                  formControlName="movingLatest"
                >
                </app-date>
              </app-form-field>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12 p0">
      <app-wizard-footer
        [hasNext]="true"
        [hasPrevious]="true"
        (nextStep)="onCheckForm()"
        (previousStep)="previousStep()"
      >
      </app-wizard-footer>
    </div>
  </div>
</div>
