<app-modal-v2>
  <div class="modal-l self-disclosure__modal">
    <app-modal-content>
      <div class="modal-body self-disclosure__content">
        <div class="self-disclosure__header">
          <p class="uppercase-m semibold self-disclosure__title">
            {{ 'self_disclosure.answers.modal_header_l' | translate }}
          </p>
          <h3 class="title-xl semibold self-disclosure__sub-title mb16">
            {{ 'self_disclosure.answers.modal_sub_title_l' | translate }}
          </h3>
        </div>

        <div class="self-disclosure__form">
          <app-self-disclosure-form
            [formControl]="control"
            [selfDisclosureModel]="answeredSelfDisclosure"
            [userData]="userData$ | async"
            [propertySearcher]="propertyMatch"
            [constants]="constants$ | async"
            [formValiditySubject]="formValiditySubject"
            (validityChange)="onFormValidityChange($event)"
          >
          </app-self-disclosure-form>
          <div
            *ngIf="answeredSelfDisclosure.description"
            class="mt10 mb10 p10 self-disclosure__info-box"
          >
            <div class="row">
              <div class="col">{{answeredSelfDisclosure.description}}</div>
            </div>
          </div>
        </div>
      </div>
    </app-modal-content>

    <app-modal-footer>
      <div class="modal-footer self-disclosure__footer">
        <div>
          <div class="d-flex justify-content-end mb-2">
            <app-button
              (clickEvent)="dismiss()"
              [disabled]="isProcessing$ | async"
              class="me-1"
              [type]="'light-bordered'"
            >
              {{'general.cancel_a' | translate}}
            </app-button>

            <app-button
              [type]="'primary'"
              (clickEvent)="save()"
              [disabled]="isProcessing$ | async"
              [loading]="isProcessing$ | async"
              class="ms-1"
              >{{ 'self_disclosure.answers.send_a' | translate}}
            </app-button>
          </div>

          <div
            *ngIf="mandatoryFieldsMissing"
            class="mandatory-fields-missing text-center"
          >
            {{ 'self_disclosure.answers.modal_mandatory_missing_l' | translate
            }}
          </div>
        </div>
      </div>
    </app-modal-footer>
  </div>
</app-modal-v2>
