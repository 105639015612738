<app-modal-v2>
  <div class="modal-m automated-rental-modal">
    <app-modal-content>
      <div class="new-home__modal d-flex flex-column justify-content-between">
        <div class="title-m text-center d-block new-home__header">
          {{'new-home.you_are_tenant_title_l' | translate}}
        </div>
        <div
          class="new-home__animation text-center d-flex justify-content-center align-items-center"
          #lottieAnimation
        >
          <ng-lottie
            [options]="lottieConfig"
            [width]="'125px'"
            [height]="'125px'"
          ></ng-lottie>
        </div>
        <div class="title-xxl mb30 text-center">
          {{'new-home.congratiulation_l' | translate}}
        </div>
        <div class="title-m text-center mb30">
          {{(haveFunWithPropertyTranslation) | translate}}
        </div>
        <div
          class="new-home__property-information d-flex flex-row mb30 justify-content-center align-items-center"
        >
          <div class="new-home__property-image pr15" *ngIf="picture?.url">
            <img [src]="picture?.url" alt="property titel bild" />
          </div>
          <div
            class="new-home__property-data"
            [class.text-center]="!picture?.url"
          >
            <strong class="m0">{{property?.data?.name}}</strong>
            <p class="mb5">{{property?.data?.address | appAddress}}</p>
            <p class="m0">
              {{property?.data?.basePrice | currency:'EUR' }} /
              {{'general.month_l' |translate}}
            </p>
          </div>
        </div>
        <div class="new-home__next-steps">
          <p class="mb8 new-home__next-steps--title">
            {{'new-home.next_steps_l' | translate}}
          </p>
          <div class="card card-body d-flex justify-center">
            <p class="mb10 d-flex flex-row align-items-center">
              <svg-icon
                src="/assets/images/icons/icon-attention-red.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
              ></svg-icon>
              <span class="pl5 title-xs title-xs--negative">
                {{'new-home.you_are_now_tenant_l' | translate}}
              </span>
            </p>
            <p class="m0 default-s mb25">
              <strong>
                <ng-container [ngSwitch]="true">
                  <ng-container
                    *ngSwitchCase="((searchProfiles$ | async)?.length > 0) && ((propertiesMatches$ | async)?.length > 0)"
                  >
                    {{'new-home.search_profile_applications_decision_l' |
                    translate}}
                  </ng-container>
                  <ng-container
                    *ngSwitchCase="(searchProfiles$ | async)?.length > 0 && !((propertiesMatches$ | async)?.length > 0)"
                  >
                    {{'new-home.search_profile_applications_decision_search-profile_l'
                    | translate}}
                  </ng-container>
                  <ng-container
                    *ngSwitchCase="!((searchProfiles$ | async)?.length > 0) && (propertiesMatches$ | async)?.length > 0"
                  >
                    {{'new-home.search_profile_applications_decision_application_l'
                    | translate}}
                  </ng-container>
                </ng-container>
              </strong>
            </p>
            <app-form-field
              *ngIf="(searchProfiles$ | async)?.length > 0"
              class="mb15 d-block"
              [class.disabled]="!activateSearchProfile"
            >
              <app-form-field-label
                >{{'new-home.search_profile_status_l' |
                translate}}</app-form-field-label
              >
              <app-slide-toggle [(ngModel)]="activateSearchProfile">
              </app-slide-toggle>
            </app-form-field>
            <app-form-field
              *ngIf="(propertiesMatches$ | async)?.length > 0"
              class="d-block"
              [class.disabled]="!closeOpenApplication"
            >
              <app-form-field-label
                >{{'new-home.close_application_l' |
                translate}}</app-form-field-label
              >
              <app-slide-toggle [(ngModel)]="closeOpenApplication">
              </app-slide-toggle>
            </app-form-field>
            <app-loading-spinner
              class="m-auto"
              *ngIf="(propertiesMatchesActionState$ | async)?.pending || (searchProfilesActionState$ | async)?.pending"
            ></app-loading-spinner>
          </div>
        </div>
        <app-button class="w-100" [type]="'primary'" (clickEvent)="close()">
          {{ 'general.thank_you_l' | translate }}
        </app-button>
      </div>
    </app-modal-content>
  </div>
</app-modal-v2>
