import { createReducer, on } from '@ngrx/store';
import {
  ApplicantStatus,
  CustomerDefinedFieldDefinition,
  CustomerDefinedFieldValue,
  Pagination,
  PropertyMatchBean
} from '@ui/shared/models';
import { ActionState, ActionStateCreator } from 'libs/state-utils';
import * as fromActions from './property-matches.actions';

function sortCustomDefinedFieldDefinitions(
  a: CustomerDefinedFieldDefinition,
  b: CustomerDefinedFieldDefinition
): number {
  return a.order - b.order;
}
function sortCustomDefinedFieldValues(
  a: CustomerDefinedFieldValue,
  b: CustomerDefinedFieldValue
): number {
  return a.definition.order - b.definition.order;
}

function sortCustomDefinedFieldsOnPropertyMatches(
  matches: PropertyMatchBean[]
) {
  return matches.map(match => {
    return {
      ...match,
      property: {
        ...match.property,
        customerDefinedFieldDefinitions: [
          ...(match.property.customerDefinedFieldDefinitions || [])
        ].sort(sortCustomDefinedFieldDefinitions),
        customerDefinedFieldValues: [
          ...(match.property.customerDefinedFieldValues || [])
        ].sort(sortCustomDefinedFieldValues)
      }
    };
  });
}

export interface PropertyMatchesState {
  propertyMatchDataActionState: ActionState;
  propertyMatchesDataActionState: ActionState;
  propertyMatchesData: PropertyMatchBean[];
  propertyMatchesPagination: Pagination;
  propertyMatchesDataLoadMoreActionState: ActionState;

  propertyMatchesRentedActionState: ActionState;
  propertyMatchesRented: PropertyMatchBean[];

  revokeAllOpenApplicationsActionState: ActionState;
  processApplicationActionState: ActionState;
  processAnonymousApplicationActionState: ActionState;
  checkApplicationExistActionState: ActionState;

  availableForNewConversation: PropertyMatchBean[];
  availableForNewConversationActionState: ActionState;

  propertyMatchQuestionsActionState: ActionState;
  checkIdentityActionState: ActionState;
}

export const initialState: PropertyMatchesState = {
  propertyMatchDataActionState: ActionStateCreator.create(),
  propertyMatchesDataActionState: ActionStateCreator.create(),
  propertyMatchesData: [],
  propertyMatchesDataLoadMoreActionState: ActionStateCreator.create(),
  propertyMatchesPagination: {
    page: 0,
    size: 6
  },
  processApplicationActionState: ActionStateCreator.create(),
  revokeAllOpenApplicationsActionState: ActionStateCreator.create(),
  processAnonymousApplicationActionState: ActionStateCreator.create(),
  checkApplicationExistActionState: ActionStateCreator.create(),

  propertyMatchesRentedActionState: ActionStateCreator.create(),
  propertyMatchesRented: [],

  availableForNewConversation: [],
  availableForNewConversationActionState: ActionStateCreator.create(),
  propertyMatchQuestionsActionState: ActionStateCreator.create(),
  checkIdentityActionState: ActionStateCreator.create()
};

export const propertyMatchesReducer = createReducer(
  initialState,
  on(fromActions.LoadPropertyMatchesBeans, (state, { loadMore = false }) => {
    const actionState = {
      propertyMatchesDataActionState: state.propertyMatchesDataActionState,
      propertyMatchesDataLoadMoreActionState:
        state.propertyMatchesDataLoadMoreActionState
    };
    if (loadMore) {
      actionState.propertyMatchesDataLoadMoreActionState =
        ActionStateCreator.onStart();
    } else {
      actionState.propertyMatchesDataActionState = ActionStateCreator.onStart();
    }
    return {
      ...state,
      ...actionState
    };
  }),
  on(
    fromActions.LoadPropertyMatchesBeansSuccess,
    (state, { result: { nodes, page }, loadMore = false }) => {
      let propertyMatchesData = [...state.propertyMatchesData];
      const actionState = {
        propertyMatchesDataActionState: state.propertyMatchesDataActionState,
        propertyMatchesDataLoadMoreActionState:
          state.propertyMatchesDataLoadMoreActionState
      };

      if (loadMore) {
        actionState.propertyMatchesDataLoadMoreActionState =
          ActionStateCreator.onSuccess();
        const ids = new Set(propertyMatchesData.map(d => d.id));
        const mappedNodes = sortCustomDefinedFieldsOnPropertyMatches(
          nodes.filter(d => !ids.has(d.id))
        );
        propertyMatchesData = [...propertyMatchesData, ...mappedNodes];
      } else {
        actionState.propertyMatchesDataActionState =
          ActionStateCreator.onSuccess();

        propertyMatchesData = sortCustomDefinedFieldsOnPropertyMatches([
          ...nodes
        ]);
      }

      return {
        ...state,
        ...actionState,
        propertyMatchesData,
        propertyMatchesPagination: page
      };
    }
  ),
  on(
    fromActions.LoadPropertyMatchesBeansFail,
    (state, { error, loadMore = false }) => {
      const actionState = {
        propertyMatchesDataActionState: state.propertyMatchesDataActionState,
        propertyMatchesDataLoadMoreActionState:
          state.propertyMatchesDataLoadMoreActionState
      };
      if (loadMore) {
        actionState.propertyMatchesDataLoadMoreActionState =
          ActionStateCreator.onError(error);
      } else {
        actionState.propertyMatchesDataActionState =
          ActionStateCreator.onError(error);
      }

      return {
        ...state,
        ...actionState,
        propertyMatchesDataLoadMore: false
      };
    }
  ),

  on(fromActions.LoadPropertyMatchesRented, state => {
    return {
      ...state,
      propertyMatchesRentedActionState: ActionStateCreator.onStart()
    };
  }),
  on(
    fromActions.LoadPropertyMatchesRentedSuccess,
    (state, { result: { nodes } }) => {
      return {
        ...state,
        propertyMatchesRented: sortCustomDefinedFieldsOnPropertyMatches(nodes),
        propertyMatchesRentedActionState: ActionStateCreator.onSuccess()
      };
    }
  ),
  on(fromActions.LoadPropertyMatchesRentedFail, (state, { error }) => {
    return {
      ...state,
      propertyMatchesRentedActionState: ActionStateCreator.onError(error)
    };
  }),

  on(fromActions.LoadPropertyMatchBean, state => ({
    ...state,
    propertyMatchDataActionState: ActionStateCreator.onStart()
  })),

  on(fromActions.LoadPropertyMatchBeanSuccess, (state, { result }) => {
    const propertyMatchesData = [...state.propertyMatchesData];
    const parsedResult: PropertyMatchBean = {
      ...result,
      property: {
        ...result.property,
        customerDefinedFieldDefinitions: [
          ...(result.property.customerDefinedFieldDefinitions || [])
        ].sort(sortCustomDefinedFieldDefinitions),
        customerDefinedFieldValues: [
          ...(result.property.customerDefinedFieldValues || [])
        ].sort(sortCustomDefinedFieldValues)
      }
    };

    const index = propertyMatchesData.findIndex(i => i.id === result.id);
    if (index > -1) {
      propertyMatchesData[index] = parsedResult;
    } else {
      propertyMatchesData.push(parsedResult);
    }

    return {
      ...state,
      propertyMatchDataActionState: ActionStateCreator.onSuccess(),
      propertyMatchesData
    };
  }),

  on(fromActions.LoadPropertyMatchBeanFail, (state, { error }) => ({
    ...state,
    propertyMatchDataActionState: ActionStateCreator.onError(error)
  })),

  on(fromActions.ApplyAnonymously, state => ({
    ...state,
    processAnonymousApplicationActionState: ActionStateCreator.onStart()
  })),

  on(fromActions.ApplyAnonymouslySuccess, state => ({
    ...state,
    processAnonymousApplicationActionState: ActionStateCreator.onSuccess()
  })),

  on(fromActions.ApplyAnonymouslyFail, (state, { error }) => ({
    ...state,
    processAnonymousApplicationActionState: ActionStateCreator.onError(error)
  })),

  on(fromActions.FindAvailableForNewConversationError, (state, { error }) => ({
    ...state,
    applicationActionState: ActionStateCreator.onError(error)
  })),

  on(fromActions.FindAvailableForNewConversation, state => ({
    ...state,
    availableForNewConversation: [],
    availableForNewConversationActionState: ActionStateCreator.onStart()
  })),

  on(
    fromActions.FindAvailableForNewConversationSuccess,
    (state, { applications }) => ({
      ...state,
      availableForNewConversation: applications,
      availableForNewConversationActionState: ActionStateCreator.onSuccess()
    })
  ),

  on(fromActions.CheckApplicationExist, state => ({
    ...state,
    checkApplicationExistActionState: ActionStateCreator.onStart()
  })),

  on(fromActions.CheckApplicationExistFail, (state, { error }) => ({
    ...state,
    checkApplicationExistActionState: ActionStateCreator.onError(error)
  })),

  on(fromActions.CheckApplicationExistSuccess, state => ({
    ...state,
    checkApplicationExistActionState: ActionStateCreator.onSuccess()
  })),

  on(fromActions.DenyProposal, fromActions.AcceptProposal, state => ({
    ...state,
    processApplicationActionState: ActionStateCreator.onStart()
  })),

  on(
    fromActions.DenyProposalSuccess,
    fromActions.AcceptProposalSuccess,
    (state, { id }) => {
      const propertyMatchesData = [...state.propertyMatchesData];
      const index = propertyMatchesData.findIndex(i => i.id === id);
      if (index > -1) {
        propertyMatchesData.splice(index, 1);
      }
      return {
        ...state,
        processApplicationActionState: ActionStateCreator.onSuccess(),
        propertyMatchesData
      };
    }
  ),

  on(
    fromActions.DenyProposalFail,
    fromActions.AcceptProposalFail,
    (state, { error }) => ({
      ...state,
      processApplicationActionState: ActionStateCreator.onError(error)
    })
  ),

  on(fromActions.CreateApplication, fromActions.RemoveApplication, state => ({
    ...state,
    processApplicationActionState: ActionStateCreator.onStart()
  })),

  on(fromActions.CreateApplicationSuccess, (state, { application }) => ({
    ...state,
    application: application,
    processApplicationActionState: ActionStateCreator.onSuccess()
  })),

  on(fromActions.RemoveApplicationSuccess, (state, { id }) => {
    const propertyMatchesData = [...state.propertyMatchesData].filter(
      pm => pm.id !== id
    );
    const propertyMatchesPagination = {
      ...state.propertyMatchesPagination,
      totalElements: state.propertyMatchesPagination.totalElements - 1
    };
    return {
      ...state,
      processApplicationActionState: ActionStateCreator.onSuccess(),
      propertyMatchesData,
      propertyMatchesPagination
    };
  }),

  on(
    fromActions.CreateApplicationFail,
    fromActions.RemoveApplicationFail,
    (state, { error }) => ({
      ...state,
      processApplicationActionState: ActionStateCreator.onError(error)
    })
  ),

  on(fromActions.DeclareIntentSuccess, (state, { id, intent }) => {
    const propertyMatchesData = [...state.propertyMatchesData].map(match => {
      if (match.id === id) {
        return {
          ...match,
          status: intent ? ApplicantStatus.INTENT : ApplicantStatus.NO_INTENT
        };
      }
      return match;
    });
    return {
      ...state,
      propertyMatchesData
    };
  }),
  on(fromActions.RevokeAllOpenApplications, state => ({
    ...state,
    revokeAllOpenApplicationsActionState: ActionStateCreator.onStart()
  })),
  on(fromActions.RevokeAllOpenApplicationsSuccess, state => ({
    ...state,
    revokeAllOpenApplicationsActionState: ActionStateCreator.onSuccess()
  })),
  on(fromActions.RevokeAllOpenApplicationsFail, (state, { error }) => ({
    ...state,
    revokeAllOpenApplicationsActionState: ActionStateCreator.onError(error)
  })),
  on(fromActions.LoadPropertyMatchBeanQuestions, state => ({
    ...state,
    propertyMatchQuestionsActionState: ActionStateCreator.onStart()
  })),
  on(
    fromActions.LoadPropertyMatchBeanQuestionsSuccess,
    (state, { id, status, questionContainer }) => {
      const isRented = status === 'TENANT';
      const data = (
        isRented ? state.propertyMatchesRented : state.propertyMatchesData
      ).map(match => {
        if (match.id === id) {
          return {
            ...match,
            questionContainer
          };
        }
        return match;
      });

      return {
        ...state,
        propertyMatchesData: isRented ? state.propertyMatchesData : data,
        propertyMatchesRented: isRented ? data : state.propertyMatchesRented,
        propertyMatchQuestionsActionState: ActionStateCreator.onSuccess()
      };
    }
  ),
  on(fromActions.LoadPropertyMatchBeanQuestionsFail, (state, { error }) => ({
    ...state,
    propertyMatchQuestionsActionState: ActionStateCreator.onError(error)
  })),
  on(fromActions.CheckIdentity, state => ({
    ...state,
    checkIdentityActionState: ActionStateCreator.onStart()
  })),
  on(fromActions.CheckIdentitySuccess, state => ({
    ...state,
    checkIdentityActionState: ActionStateCreator.onSuccess()
  })),
  on(fromActions.CheckIdentityFail, (state, { error }) => ({
    ...state,
    checkIdentityActionState: ActionStateCreator.onError(error)
  }))
);

export const getPropertyMatchesDataActionState = (
  state: PropertyMatchesState
) => state.propertyMatchesDataActionState;
export const getPropertyMatchesData = (state: PropertyMatchesState) =>
  state.propertyMatchesData;
export const getPropertyMatchesRented = (state: PropertyMatchesState) =>
  state.propertyMatchesRented;
export const getPropertyMatchesRentedActionState = (
  state: PropertyMatchesState
) => state.propertyMatchesRentedActionState;
export const getPropertyMatchesPagination = (state: PropertyMatchesState) =>
  state.propertyMatchesPagination;
export const getProcessApplicationActionState = (state: PropertyMatchesState) =>
  state.processApplicationActionState;
export const getPropertyMatchDataActionState = (state: PropertyMatchesState) =>
  state.propertyMatchDataActionState;
export const getProcessAnonymousApplicationActionState = (
  state: PropertyMatchesState
) => state.processAnonymousApplicationActionState;
export const getAvailableForNewConversation = (state: PropertyMatchesState) =>
  state.availableForNewConversation;
export const getAvailableForNewConversationActionState = (
  state: PropertyMatchesState
) => state.availableForNewConversationActionState;
export const getCheckApplicationExistActionState = (
  state: PropertyMatchesState
) => state.checkApplicationExistActionState;
export const getPropertyMatchesDataLoadMoreActionState = (
  state: PropertyMatchesState
) => state.propertyMatchesDataLoadMoreActionState;
export const getPropertyMatchQuestionsActionState = (
  state: PropertyMatchesState
) => state.propertyMatchQuestionsActionState;
export const getCheckIdentityActionState = (state: PropertyMatchesState) =>
  state.checkIdentityActionState;
