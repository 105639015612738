<div class="self-disclosure-address__content">
  <form [formGroup]="getFormControl">
    <div class="d-flex flex-row mt25 mb10">
      {{ selfDisclosureQuestion.title | translate }}
    </div>

    <div class="row">
      <div class="col-6">
        <app-form-field>
          <app-form-field-label
            >{{ 'self_disclosure.answers.employment_job_l' | translate
            }}</app-form-field-label
          >
          <input
            appInput
            type="text"
            [required]="selfDisclosureQuestion.mandatory"
            [placeholder]="'forms.type_job_name' | translate"
            formControlName="job"
            class="form-control"
          />
        </app-form-field>
      </div>
      <div class="col-6">
        <app-form-field>
          <app-form-field-label
            >{{ 'self_disclosure.answers.employment_employer_l' | translate
            }}</app-form-field-label
          >
          <input
            appInput
            type="text"
            [required]="selfDisclosureQuestion.mandatory"
            [placeholder]="'forms.type_employer' | translate"
            formControlName="employer"
            class="form-control"
          />
        </app-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <app-form-field>
          <app-form-field-label
            >{{ 'self_disclosure.answers.employment_net_income_l' | translate
            }}</app-form-field-label
          >
          <input
            appInput
            type="number"
            [required]="selfDisclosureQuestion.mandatory"
            [placeholder]="'forms.type_net_income' | translate"
            formControlName="netIncome"
            class="form-control"
          />
        </app-form-field>
      </div>
    </div>
  </form>
</div>
