import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators
} from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import moment from 'moment';

import { customEmailValidator } from 'libs/components/legacy/form/controls/validation';
import { GenderType, NameValue } from '@ui/shared/models';

import { RentDepositBaseStep } from 'tenant-pool/components/rent-deposit-wizard/rent-deposit-base-step.directive';
import {
  GERMAN_COUNTRY_CODE,
  GERMAN_COUNTRY_NAME
} from 'libs/config/country-config';
import { RegexTypes } from 'libs/utils';

@UntilDestroy()
@Component({
  selector: 'app-rent-deposit-personal-information',
  templateUrl: './rent-deposit-personal-information.component.html',
  styleUrls: ['./rent-deposit-personal-information.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RentDepositPersonalInformationComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RentDepositPersonalInformationComponent),
      multi: true
    }
  ]
})
export class RentDepositPersonalInformationComponent
  extends RentDepositBaseStep
  implements OnInit, ControlValueAccessor
{
  @Input() genderTypes: NameValue<string>[];

  public formGroup: FormGroup;

  public internationalOptions: NameValue[] = [
    { name: 'Germany', value: '+49' },
    { name: 'Norway', value: '+47' },
    { name: 'Poland', value: '+48' },
    { name: 'Belgium', value: '+32' },
    { name: 'Luxemburg', value: '+352' },
    { name: 'France', value: '+33' },
    { name: 'Spain', value: '+34' },
    { name: 'Switzerland', value: '+41' },
    { name: 'Austria', value: '+43' },
    { name: 'Italy', value: '+39' },
    { name: 'Slowakia', value: '+421' },
    { name: 'Hungary', value: '+36' },
    { name: 'Greece', value: '+30' },
    { name: 'Bulgary', value: '+359' }
  ];

  public startBirthDate: NgbDateStruct = {
    year: moment().year() - 18,
    month: moment().month(),
    day: moment().date()
  };

  public maxBirthDate: NgbDateStruct = {
    year: moment().year(),
    month: moment().month(),
    day: moment().date()
  };

  public minBirthDate: NgbDateStruct = {
    year: moment().year() - 120,
    month: moment().month(),
    day: moment().date()
  };

  private onChange: (args) => any = () => null;
  private onTouch: () => any = () => null;

  public get addressFormConfig() {
    return {
      countryName: true,
      readOnlyCountryName: true,
      countryNameToolTip: 'rent_deposit.personal_information.country_hint_l'
    };
  }

  public get addressForm() {
    return this.formGroup.get('tenantData').get('currentAddress');
  }

  public get tenantGenderList() {
    return (
      this.genderTypes?.filter(
        gender => gender.value !== GenderType.INTERSEXUAL
      ) || []
    );
  }

  constructor(private fb: FormBuilder) {
    super();
  }

  public ngOnInit(): void {
    this.formGroup = this.fb.group({
      tenantData: this.fb.group({
        gender: [null],
        firstname: [null, Validators.maxLength(30)],
        lastname: [null, Validators.maxLength(30)],
        nationality: [GERMAN_COUNTRY_CODE],
        dateOfBirth: [null],
        emailAddress: [
          null,
          Validators.compose([
            Validators.required,
            customEmailValidator,
            Validators.maxLength(50)
          ])
        ],
        phoneNumberPrefix: [null, Validators.pattern(/[0+][0-9]{2,7}/)],
        phoneNumber: [
          null,
          Validators.compose([
            Validators.maxLength(15),
            Validators.pattern(RegexTypes.PHONE)
          ])
        ],
        currentAddress: this.fb.group({
          city: [null, Validators.maxLength(25)],
          zipCode: [
            null,
            {
              validators: Validators.compose([
                Validators.minLength(4),
                Validators.maxLength(5)
              ]),
              updateOn: 'blur'
            }
          ],
          street: [null, Validators.maxLength(30)],
          houseNumber: [null, Validators.maxLength(10)],
          countryName: [GERMAN_COUNTRY_NAME],
          country: [GERMAN_COUNTRY_CODE],
          district: [null]
        })
      })
    });

    this.formGroup.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      this.onChange(value);
      this.onTouch();
    });
  }

  public nextStep() {
    super.nextStep();
  }

  public validate() {
    return this.formGroup.valid ? null : { missingFields: true };
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public writeValue(obj: any): void {
    this.formGroup.patchValue(obj);
  }
}
