import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-guest-register-modal',
  templateUrl: './guest-register-modal.component.html',
  styleUrls: ['./guest-register-modal.component.scss']
})
export class GuestRegisterModalComponent implements OnInit {
  public email: string;
  public declareIntent: boolean;
  public isApplying: boolean;
  public itpCustomerName: string;

  constructor(
    private ngbActiveModal: NgbActiveModal,
    private formBuilder: FormBuilder
  ) {}

  public form = this.formBuilder.group({
    email: [''],
    password: ['', Validators.required],
    acceptPolicies: [false, Validators.requiredTrue]
  });

  ngOnInit() {
    this.form.patchValue({
      email: this.email
    });
  }

  public dismiss() {
    this.ngbActiveModal.dismiss();
  }

  public continueAsGuest() {
    this.ngbActiveModal.close();
  }

  public register() {
    if (this.form.invalid) return;
    this.ngbActiveModal.close(this.form.value);
  }
}
