<div class="schufa-benefits-card d-flex">
  <div class="schufa-benefits-card__column col-4">
    <div class="schufa-benefits-card__image mb15">
      <img
        src="/assets/images/schufa_in_kooperation_300x141.jpg"
        alt="'schufa.cooperation_image_l' | translate"
      />
    </div>
    <picture class="schufa-benefits-card__image">
      <source
        srcset="
          /assets/images/certificates/geotrust@2x.png 2x,
          /assets/images/certificates/geotrust.png    1x
        "
      />
      <img
        src="/assets/images/certificates/geotrust.png"
        alt=""
        itemprop="image"
      />
    </picture>
  </div>
  <div class="schufa-benefits-card__column col-8">
    <ul class="schufa-benefits-card__list">
      <li
        class="schufa-benefits-card__list-item"
        [innerHTML]="'schufa.benefit_official_l' | translate"
      ></li>
      <li
        class="schufa-benefits-card__list-item"
        [innerHTML]="'schufa.benefit_fast_l' | translate"
      ></li>
      <li
        class="schufa-benefits-card__list-item"
        [innerHTML]="'schufa.benefit_pricing_l' | translate"
      ></li>
    </ul>
  </div>
</div>
