import { Inject, OnInit, Directive } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';

import {
  Router,
  ActivatedRoute,
  NavigationEnd,
  ActivatedRouteSnapshot
} from '@angular/router';

import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { FooterLink } from 'libs/components/legacy/footer-v2';
import { mobileBreakingPoints } from 'libs/components/legacy/messenger/model/interface';
import { WINDOW_REF } from 'libs/infrastructure';

import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import {
  Language,
  CookiePreference,
  AvailableLanguageCodesEnum
} from '@ui/shared/models';

import * as fromBaseState from 'libs/infrastructure/base-state';
import * as fromAppState from 'tenant-pool/+state';

import { footerConfig } from 'tenant-pool/config';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive()
export abstract class BaseShellComponent implements OnInit {
  public footerLinks: FooterLink[] = footerConfig.links;

  public showWarningBanner = false;
  public warningBannerConfig = {
    header: 'property_searcher.performance_banner.header_l',
    message: 'property_searcher.performance_banner.message_l'
  };

  public cookiesPreference$: Observable<CookiePreference>;
  public confirmedPerformanceWarning$: Observable<boolean>;

  public availableLanguages$: Observable<Language[]>;
  public defaultLanguageCode$: Observable<AvailableLanguageCodesEnum>;
  public currentLanguageCode$: Observable<AvailableLanguageCodesEnum>;

  public displayFooter = true;
  public displayHeader = true;
  public displayEmailBanner = true;
  public hideMobileMenu = false;
  public isLoggedIn = false;

  constructor(
    protected store: Store<fromBaseState.AppState>,
    protected router: Router,
    protected route: ActivatedRoute,
    protected translate: TranslateService,
    protected observer: BreakpointObserver,
    @Inject(WINDOW_REF) protected windowRef: Window
  ) {}

  public ngOnInit() {
    this.store.dispatch(new fromBaseState.GetSystemDowntime());

    this.showWarningBanner =
      this.translate.instant('SHOW_PERFORMANCE_BANNER_PS') === 'ENABLED';
    this.cookiesPreference$ = this.store.select(
      fromBaseState.getCookiesPreference
    );
    this.confirmedPerformanceWarning$ = this.store.select(
      fromBaseState.getPerformanceWarningConfirmed
    );
    this.availableLanguages$ = this.store.select(
      fromBaseState.getAvailableLanguages
    );
    this.defaultLanguageCode$ = this.store.select(
      fromBaseState.getDefaultLanguageCode
    );
    this.currentLanguageCode$ = this.store.select(
      fromBaseState.getCurrentLocale
    );

    this.store
      .select(fromAppState.getUserData)
      .pipe(untilDestroyed(this))
      .subscribe(userData => {
        this.isLoggedIn = !!userData;
      });

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.route),
        map(route => route.firstChild.snapshot)
      )
      .subscribe(snapshot => {
        this.windowRef.scrollTo(0, 0);
        this.updateVisibility(snapshot);
      });
    this.updateVisibility(this.route?.snapshot?.firstChild);
  }

  public onLanguageChange(languageCode: AvailableLanguageCodesEnum) {
    this.isLoggedIn
      ? this.store.dispatch(
          new fromAppState.ChangePreferredLanguage(languageCode, true)
        )
      : this.store.dispatch(new fromBaseState.ChangeLocale(languageCode));
  }

  public confirmPerformanceWarning() {
    this.store.dispatch(
      new fromBaseState.SetPerformanceWarningConfirmation(true)
    );
  }

  public getDataOfAllSnapshotNodes = (snapshot: ActivatedRouteSnapshot) => {
    const dataArr = [snapshot.data];
    if (snapshot.children.length) {
      dataArr.push(...this.getDataOfAllSnapshotNodes(snapshot.children[0]));
    }
    return dataArr;
  };

  public getDataFlagRecursively(
    dataArr: ActivatedRouteSnapshot['data'][],
    name: string,
    defaultValue: boolean
  ) {
    const dataWithFlagSet = dataArr.find(
      dataObj => typeof dataObj[name] !== 'undefined'
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return typeof dataWithFlagSet !== 'undefined'
      ? dataWithFlagSet[name]
      : defaultValue;
  }

  protected updateVisibility(snapshot: ActivatedRouteSnapshot) {
    const reversedDataArr = this.getDataOfAllSnapshotNodes(snapshot).reverse();
    this.hideMobileMenu =
      this.observer.isMatched(mobileBreakingPoints) &&
      this.getDataFlagRecursively(reversedDataArr, 'hideMobileMenu', false);
    this.displayFooter = !this.getDataFlagRecursively(
      reversedDataArr,
      'hideFooter',
      false
    );
    this.displayHeader = !this.getDataFlagRecursively(
      reversedDataArr,
      'hideHeader',
      false
    );
    this.displayEmailBanner = !this.getDataFlagRecursively(
      reversedDataArr,
      'hideEmailBanner',
      false
    );
  }
}
