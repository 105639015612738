<app-modal-v2>
  <div class="modal-m guest-set-password__modal">
    <app-modal-content>
      <div class="modal-body">
        <div class="guest-register-modal__header">
          <div class="d-flex justify-content-between">
            <app-logo [preventClick]="true"></app-logo>
          </div>
          <h2 class="mt16 uppercase-m semibold modal-header__title">
            {{'guest_mode.register_modal.title_l' | translate}}
          </h2>
          <h3 class="title-xl modal-header__subtitle">
            {{'guest_mode.register_modal.sub_title_l' | translate}}
          </h3>
        </div>

        <p
          *ngIf="declareIntent"
          class="ml16 mr16"
          [innerHTML]="'guest_mode.register_modal.intent_l' | translate"
        ></p>
        <p
          *ngIf="isApplying"
          class="ml16 mr16"
          [innerHTML]="(itpCustomerName ?
              'guest_mode.application.register_advantages_itp_l' : 'guest_mode.application.register_advantages_no_itp_l') | translate:{customerName: itpCustomerName}"
        ></p>

        <form
          [formGroup]="form"
          class="guest-set-password__form"
          autocomplete="off"
        >
          <div class="col-12 mb20">
            <app-form-field>
              <app-form-field-label
                >{{ 'general.email_l' | translate}}</app-form-field-label
              >
              <input
                appInput
                type="text"
                [readonly]="true"
                formControlName="email"
                class="form-control"
              />
            </app-form-field>
          </div>

          <div class="col-12 mb20">
            <app-password
              [label]="'forms.type_password'"
              [showRequiredBox]="true"
              placeholder="{{ 'forms.password_placeholder' | translate}}"
              formControlName="password"
            >
            </app-password>
          </div>

          <div class="col-12 terms-of-use">
            <app-form-field>
              <app-checkbox
                #acceptedTermsAndConditions
                [required]="true"
                [innerHTML]="'register.agreement_with_terms_l' | translate"
                formControlName="acceptPolicies"
              ></app-checkbox>
            </app-form-field>
          </div>
        </form>
      </div>
    </app-modal-content>

    <app-modal-footer>
      <div class="modal-footer guest-set-password__footer">
        <app-button (clickEvent)="dismiss()" [type]="'light-bordered'"
          >{{'general.cancel_a' | translate}}</app-button
        >
        <app-button
          *ngIf="isApplying"
          (clickEvent)="continueAsGuest()"
          [disabled]="!acceptedTermsAndConditions.value"
          [type]="'light-bordered'"
          >{{'guest_mode.register_modal.continue_as_guest_a' |
          translate}}</app-button
        >
        <app-button
          (clickEvent)="register()"
          [disabled]="form.invalid"
          [type]="'primary'"
          >{{'guest_mode.application.register_now_l' | translate }}</app-button
        >
      </div>
    </app-modal-footer>
  </div>
</app-modal-v2>
